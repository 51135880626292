
.filter-coupon {
  $selfCoupon: &;
  border: 1px solid $season-discount-filter-border;
  border-radius: 6px;
  padding: 8px 24px;
  color: $white;
  flex: 0 0 30%;
  transition: background-color $ease $timing-5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: $season-discount-filter-background;
  position: relative;
  overflow: hidden;;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    .circulo{
      stroke: $season-discount-filter-phrase-background;
      opacity: 0.5
    }
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 2;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }

  &__phrase {
    transition: all $ease $timing-5;
    background: $season-discount-filter-phrase-background;
    padding: 0px 8px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 0px;
    border-radius: 12px;

    &--empty {
      display: none;
    }

    &__container {
      width: 100%;
      text-align: left;
      transform: translateY(10px);
    }
  }

  &__discount {
    white-space: nowrap;
    font-size: 32px;
    font-weight: 900;
    text-align: center;
  }

  @media (hover: hover) {
    &:hover {
      background: $season-discount-filter-active-background;
      background-position: center center;
      background-size: cover;
      #{$selfCoupon}__phrase {
        background: $season-discount-filter-active-phrase-background;
      }
      .circulo{
        opacity: 0.1 !important;
        stroke: $season-discount-filter-active-circle-stroke !important;
      }
    }
  }

  &--active {
    background: $season-discount-filter-active-background;
    background-position: center center;
    background-size: cover;
    #{$selfCoupon}__phrase {
      background: $season-discount-filter-active-phrase-background;
    }
    .circulo{
      opacity: 0.1 !important;
      stroke: $season-discount-filter-active-circle-stroke !important;
    }
  }


  &--small {
    #{$selfCoupon}__content {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    #{$selfCoupon}__discount {
      line-height: 16px;

      span {
        font-weight: normal;
        vertical-align: super;
        font-size: 12px;
        position: relative;
        top: 1px;
        &:before {
          content: '';
          display: block;
        }
      }
    }

    #{$selfCoupon}__phrase {
      &__container {
        flex: 0;
      }
    }
  }

  @media screen and (max-width: $tablet-max) {
    &__phrase {
      &__container {
        transform: translateY(0);
      }
    }
    &__discount {
      font-size: 24px;
    }
  }

  @media screen and (max-width: $tablet-min) {
    &--small {
      #{$selfCoupon}__discount {
        line-height: 9px;
      }
    }
    &__phrase {
      &__container {
        transform: translateY(0);
      }
    }
    &__discount {
      font-size: 16px;
    }
  }

  @media screen and (max-width: $mobile-max) {
    &:hover {
      border: 1px solid $season-discount-filter-border;
    }
    padding: 8px;
    &--small {
      svg{
        width: 150%;
      }
    }
  }
}
