.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: var(--gray-900);
  overflow-x: scroll;
  width: 100%;
  border-top: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  max-height: 3.625rem;

  div > a, button {
    text-decoration: none;

    :hover {
      text-decoration: underline;
      color: var(--gray-900);
      cursor: pointer;
    }
  }

  div > button {
    all: unset;
  }

  & svg {
    vertical-align: baseline !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__section {
    display: flex;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    margin: 0.5rem 0;

    &.short {
      border-right: 1px solid var(--gray-200);
    }

    &__categories {
      max-width: 57rem;
      display: flex;
      align-items: center;
    }
  }

  &__category-label {
    display: inline-block;

    &__ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 9.25rem;
    }
  }

  &__category-link {
    display: flex;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: var(--black);
    }
  }

  .dropdown-menu {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -0.25rem;
  }

  .home-section {
    border-right: 1px solid var(--gray-200);
    width: max-content;
  }

  @media (min-width: $desktop-m) {
    &__section {
      &.short {
        padding-left: 0;
      }
    }
  }
}
