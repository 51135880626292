

.results-for {
  display: flex;
  justify-content: space-between;
  color: $white;
  max-width: 100%;
  margin-right: 24px;
  align-items: center;

  &__search-term {
    text-align: left;
    white-space: nowrap;
  }

  &__showing-discounts {
    white-space: nowrap;
  }

  @media (max-width: 700px) {
    strong {
      display: block;
    }
  }
}
