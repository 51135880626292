.home__banner--black-november {
  width: 100%;
  height: 11rem;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.2rem;

  @media (min-width: $desktop) {
    height: 16.375rem;
    padding: 1.5rem 3rem;
  }

  &__highlight-text {
    background: #f5cf67;
    color: #c5310a;
    font-family: 'RobotCrush';
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: fit-content;
    padding: 0 0.8rem;

    @media (min-width: $desktop) {
      font-size: 2rem;
    }
  }

  &__logo {
    margin-left: 1.5rem;

    @media (min-width: $desktop) {
      margin-left: 19rem;
    }
  }

  &__top-logo {
    background-image: url(/s/static/images/black-november/black-november-home-banner-mobile.jpg);
    background-position: top right;

    @media (min-width: $desktop) {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(/s/static/images/black-november/black-november-home-banner-2.png),
        linear-gradient(
          193deg,
          rgba(244, 75, 40, 0.85) 8.5%,
          rgba(0, 0, 0, 0.12) 77.69%
        );
      background-color: var(--black);
    }
  }

  &__footer-logo {
    background-image: url(/s/static/images/black-november/black-november-home-banner-footer.svg);
    height: auto;
    background-position: center;

    @media (max-width: 370px) {
      background-position-x: -100px;
    }

    @media (min-width: $desktop) {
      background-position-y: -38px;
      background-position-x: inherit;
    }
  }
}
