.fixed-campaign-banner {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0.375rem;
  overflow: hidden;
  min-height: 14rem;
  max-height: 19rem;
  background-size: cover;
  position: relative;

  @media (max-width: $tablet-mid) {
    flex: 0 0 100%;
    border-radius: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100%;
    flex: 1;
  }

  &__highlight {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    &-title {
      font-family: Nunito Sans;
      font-weight: var(--font-weight-bold);
      font-size: 1.75rem;
      line-height: 120%;
      color: var(--white);
    }

    &-image {
      width: auto;
      max-height: 2rem;
      margin-right: 0.5rem;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    z-index: 1;
    flex: 1;

    &-title {
      font-size: 1rem;
      line-height: 120%;
      font-weight: var(--font-weight-bold);
      color: var(--white);
      word-break: break-word;
    }

    &-description {
      font-size: 0.875rem;
      line-height: 150%;
      color: var(--white);
      word-break: break-word;
      margin: 0;
    }

    &-button {
      text-align: center;
      border-radius: 6px;
      cursor: pointer;
      border: 1px solid transparent;
      text-decoration: none;
      transition: 0.5s all $ease;
      padding: 0.25rem 0.5rem;
      margin-top: 0.75rem;
      white-space: nowrap;
      width: fit-content;
      font-size: 0.875rem;
    }
  }

  &__background-logo {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .lancamentos {
    .fixed-campaign-banner {
      &__cta {
        &-title {
          font-size: 1.5rem;
        }

        &-description {
          @media (min-width: $tablet-mid) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: $tablet-min) {
  .fixed-campaign-banner {
    &__cta {
      &-description {
        max-width: 69%;
      }
    }
  }
}

@media (min-width: $tablet-mid) {
  .fixed-campaign-banner {
    max-height: 14rem;

    &__container {
      padding: 1.5rem;
    }

    &__highlight {
      margin-bottom: 0.75rem;

      &__title {
        font-size: 2.25rem;
      }

      &__image {
        max-height: 2.5rem;
        margin-right: 1rem;
      }
    }

    &__cta {
      &-description {
        font-size: 1rem;
      }

      &-button {
        font-size: 16px;
      }
    }

    &__background-logo {
      max-height: 14rem;
    }
  }
}

@media (min-width: 62.8125rem) {
  .fixed-campaign-banner {
    margin-bottom: 0;
  }
}
