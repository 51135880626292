.signup-card {
  height: 100%;
  min-height: 100%;

  &__link {
    border: 1px solid rgba(119, 119, 119, 0.45);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    height: 100%;
    max-height: 202px;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    transition: all ease-in-out 0.25s;
    width: 100%;

    @media (min-width: $mobile-l) {
      height: 100%;
      min-height: unset;
      max-height: 215px;
    }

    @media (min-width: 768px) {
      max-height: 260px;
    }

    @media (min-width: $desktop-m) {
      max-height: 301px;
      padding: 2rem;
    }

    &:hover,
    &:focus {
      background-color: $gray-3;

      h2 {
        color: $black;
      }
    }
  }

  &__icon {
    font-size: 2.5rem;
    margin: 0;
  }

  &__title {
    font-size: 1rem;
    font-weight: 900;
    line-height: 1;

    @media (min-width: $desktop-m) {
      font-size: 1.5rem;
    }
  }

  &__description {
    font-size: 1rem;
    line-height: 1;
    margin: 0;
  }

  &__button {
    background-color: #009d43;
    border: none;
    border-radius: .25rem;
    color: $white;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    padding: 0.5rem 1rem;
    text-wrap: nowrap;

    @media (min-width: $desktop) {
      font-size: 1rem;
      padding: 1rem 2.25rem;
    }

    svg {
      margin: -0.25rem 0 0 0.5rem;
      height: auto;
      width: 1rem;
    }
  }
}
