@font-face {
  font-family: 'RobotCrush.woff';
  src: url('../fonts/robot_crush.woff') format('woff');
}

@media (min-width: 320px) {
  .season-black-november .body__content {
    padding: 0 !important;
    background: #000000;
  }

  .season-black-november .hm-mktplace-header--not-flying .hm-mktplace-header__container,
  .season-black-november .button-iconed,
  .season-black-november .body {
    background: var(--black) !important;
  }

  .season-black-november .hm-mktplace-header--not-flying .hm-mktplace-header__container {
    border-bottom: 1px solid var(--gray-700);
  }

  .season-black-november .hm-mktplace-header.hm-mktplace-header--not-flying{
    background: transparent;
  }

  .season-black-november .page-container__content {
    display: flex;
    width: 100%;
    padding-top: 2rem;
    top: 2rem !important;
  }

  .season-black-november .season-header {
    background-image: url(/s/static/images/black-november/banner-black-november.svg);
    height: 300px;
  }

  .season-black-november .ordering-filter__options {
    background: #000000;
    color: #FFFFFF;
    font-weight: 700;
  }

  .season-black-november .ordering-filter__options-item.active,
  .season-black-november .button-iconed__marker,
  .season-black-november .ordering-filter__modal-list__item .ordering-filter__modal-list__item-input:checked ~ .ordering-filter__modal-list__item-label,
  .season-black-november .hm-mktplace-menu__purchases--mobile {
    color: #FF9377 !important;
  }

  .season-black-november .ordering-filter__options::after {
    border: 11px solid #000000;
  }

  .season-black-november .ordering-filter__box-shadow.ordering-filter__box-shadow-active{
    box-shadow: none;
    margin-top: 34px;
  }

  .season-black-november .breadcrumbs {
    display: none;
  }

  .season-header.black-november .season-header__container__head__secondary div {
    font-family: 'RobotCrush', sans-serif;
    font-style: normal;
    background: #E5402B;
    color: #FFF4D7;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    padding: 0.25rem 0.75rem;
    margin-bottom: 0.25rem;
  }

  .season-header.black-november .season-header__container__head__secondary div:first-child {
    width: 70%;
  }

  .season-black-november.season--header-scrolled .hm-mktplace-header__container {
    background-color: var(--black) !important;
  }

  .season-black-november .results__aux-bar__search,
  .season-black-november .no-results__title,
  .season-black-november .no-results__text,
  .season-black-november .no-results__most-wanted__title,
  .season-black-november .results__search-term,
  .season-black-november .results__search-term :nth-child(2),
  .season-black-november .product-card-alt--black-november .product-card-alt__description,
  .season-black-november .hm-mktplace-menu__menu-content__section .hm-mktplace-menu__name,
  .season-black-november .button-iconed__icon-marker .button-iconed__marker,
  .season-black-november .load-more button {
    color: #FFFFFF !important;
  }

  .season-black-november .no-results {
    background: rgba(25, 28, 31, 0.50);
    padding: 4rem 1rem;
    border-radius: 0.75rem;
  }

  .season-black-november .no-results .no-results__container {
    margin-bottom: 8rem;
    flex-wrap: nowrap;
  }

  .season-black-november .hot-form__label span,
  .season-black-november .ordering-filter__button,
  .season-black-november .button-iconed__label,
  .season-black-november .selectable-list-counter .show-all-button {
    color: #FFFFFF !important;
    font-weight: 700 !important;
  }

  .season-black-november .selectable-list-counter .show-all-button {
    margin-bottom: 1.5rem;
  }

  .season-black-november span.selectable-list-counter__item__counter,
  .season-black-november .breadcrumbs__title {
    color: var(--gray-500) !important;
    font-weight: 700;
  }

  .season-black-november .season-header span {
    height: inherit !important;
  }

  .season-black-november .show-all-button {
    text-decoration-line: underline;
  }

  .season-black-november .show-all-button path,
  .season-black-november .hm-mktplace-menu__menu-content__item path {
    fill: #FF9377;
  }

  .season-black-november .ordering-filter__button path {
    fill: var(--gray-200);
  }

  .season-black-november .results-filter-placeholder__title__type {
    color: var(--gray-200) !important;
    font-size: 20px;
  }

  .season-black-november .results-filter-placeholder__title:not(:first-child),
  .season-black-november .results__aux-bar,
  .season-black-november .mobile-filters {
    border-top: 1px solid var(--gray-700) !important;
  }

  .season-black-november .mobile-filters {
    padding-top: 1rem;
  }

  .season-black-november .ordering-filter__button-text, 
  .season-black-november .ordering-filter__options-item,
  .season-black-november .product-card-alt--black-november .product-card-alt__rating__total {
    color: var(--gray-200) !important;
  }

  .season-black-november .results-filter-placeholder__title .content-loader--shine {
    background-image: linear-gradient(90deg, var(--gray-800) 0, var(--gray-800) 45%, var(--gray-700) 90%) !important;
  }

  .season-black-november .ordering-filter__options-item {
    font-weight: 100;
  }

  .season-black-november .filter-fade {
    background-image: linear-gradient(to bottom, rgba(24, 23, 23, 0), rgba(8, 8, 8 , 0.87) 90%) !important;
  }

  .season-black-november .hot-form__input:checked ~ .hot-form__label::before,
  .season-black-november .ordering-filter__modal-list__item .ordering-filter__modal-list__item-input:checked ~ .ordering-filter__modal-list__item-label:before {
    background-color: #C5310A !important;
    box-shadow: 0 0 0 var(--input-border-width) #C5310A !important;
  }

  .season-black-november .selectable-list-counter__item__icon .hot-form--custom .hot-form__input:hover ~ .hot-form__label::before, .selectable-list-counter__item__icon .hot-form--custom .hot-form__input:focus ~ .hot-form__label::before {
    box-shadow: 0 0 0 0.2rem #FF9377 !important;
  }

  .season-black-november .selectable-list-counter__item__icon .hot-form--custom .hot-form__input:focus ~ .hot-form__label::before {
    box-shadow: 0 0 0 var(--input-border-width) var(--gray-400) !important;
  }

  .season-black-november .hot-form__label::before,
  .season-black-november .selectable-list-counter .show-all-button,
  .season-black-november .load-more button {
    background-color: transparent !important;
  }

  .season-black-november .product-card-alt__badge-certification,
  .season-black-november .hm-suggestions-list__content .hm-suggestions-list__suggestion__link {
    background-color: var(--gray-600);
    border: 1px solid var(--gray-500);
  }

  .season-black-november .season-header__container__head {
    align-items: flex-start;
    justify-content: space-around;
  }

  .season-black-november .hm-mktplace-header__container {
    color: var(--white);
    background-color: #181c1f;
  }

  @media (min-width: 1024px) {
    .season-black-november .hm-mktplace-header__container  .hm-mktplace-header__logo {
      margin-top: 0px;
    }
  }

	.season-black-november .hm-mktplace-header__container  .hm-mktplace-header__logo svg {
		max-width: 180px;
  }

  .season-black-november .hm-mktplace-header__container .hm-mktplace-header__session__signin {
    color: var(--white);
    border-color: 1px solid var(--white);
  }

  .season-black-november .hm-mktplace-header__container .hm-mktplace-header__session__signup {
    border-color: var(--white);
    background: var(--white);
    color: var(--black);
    font-weight: 400;
  }

  .season-black-november .results-container {
    padding-top: 0 !important;
  }

  .season-black-november .hm-mktplace-header__user,
  .season-black-november .ordering-filter__modal-mobile {
    color: #ffffff;
    font-weight: bold;
  }

  .season-black-november .season-header .page-container__content {
    position: absolute !important;
  }

  .season-black-november .season-header__container{
    padding: 0 .5rem;
    width: inherit;
  }

  .season-black-november .hm-mktplace-header__categories {
    color: #ffffff;
    font-weight: bold;
    margin-left: 3.2rem;
  }

  .season-black-november .hm-mktplace-header__categories path,
  .season-black-november .button-iconed__icon path,
  .season-black-november .hm-mktplace-header__container path,
  .season-black-november .hm-search-close-button path {
    fill: #ffffff;
  }

  .season-black-november .hm-mktplace-search-bar {
    width: 90%;
  }

  .season-black-november .black-november-info {
    width: 600px !important;
    height: 75px !important;
  }

  .season-black-november .black-november-info div {
    font-family: 'RobotCrush';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: var(--white);
    text-align: initial;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }

  .season-black-november .black-november-info img {
    object-fit: contain;
  }

  .season-black-november .black-november-info .black-november__progress {
    margin-top: 0.75rem;
  }

  .season-black-november .black-november-info__text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: initial;
    color: #FFFFFF;
    letter-spacing: 0.32px;
    margin-top: 3rem;
  }

  .season-black-november .ordering-filter__button ._text-decoration-underline {
    font-size: 1rem;
  }

  .season-black-november .black-november-info__text div {
    margin: 1.5rem 0;
  }

  .season-black-november .hm-mktplace-card__product {
    display: none !important;
  }

  .season-black-november .hm-mktplace-search__autocomplete__data-container {
    margin-top: -1.5rem;
  }

  .season-black-november .filter-modal__filters__title__label,
  .season-black-november .hm-mktplace-menu__purchases__link-item {
    color: #FF9377;
  }

  .season-black-november .results-page__black-november {
    border-top: 1px solid;
  }

  .season-black-november .selectable-list-counter .hot-form--checkbox .hot-form__input:checked ~ .hot-form__label::after {
    background-color: #FE734F;
    border-radius: 5px;
  }

  .season-black-november .hm-mktplace-header__options {
    border-radius: 0;
  }

  .season-black-november .results-page__black-november .page-container__content {
    margin-top: 2rem;
  }

  .season-black-november .season-header__logo {
    width: 300px;
    display: none;
    background-image: url(/s/static/images/black-november/banner-image.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .season-black-november .hm-mktplace-footer {
    margin-top: 5rem;
  }

  .season-header--cyber-week .season-header__logo {
    background-image: url(/s/static/images/cyber-week/cyber-week-logo.png);
    width: 360px;
    height: 100px;
  }

  .season-header--cyber-week .page-container__content {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }

  .season-header.season-header--cyber-week {
    background-color: #FE734F!important;
    background-position: inherit!important;
  }

  .season-header--cyber-week .season-header__container__head__message__h1 {
    color: #000000;
    padding-top: 1rem;
    text-align: center;
  }

  .season-black-november .hm-mktplace-header--not-flying .hm-mktplace-header__logo__link svg path,
  .season-black-november .filter-modal__header path,
  .season-black-november .hm-mktplace-search__container__close path,
  .season-black-november .hm-mktplace-header__user path,
  .season-black-november .filter-modal__footer__apply path {
    fill: #ffffff;
  }

  .season-black-november .hm-mktplace-header--not-flying .hm-mktplace-header-logo g path {
    fill: #F04E23;
  }

  .season-header .black-november,
  .season-black-november .filter-modal__filters,
  .season-black-november .ordering-filter__modal-mobile,
  .season-black-november .filter-modal__header,
  .season-black-november .filter-modal__footer__apply,
  .season-black-november .hm-mktplace-search__container__body,
  .season-black-november .hm-mktplace-search__container,
  .season-black-november .hm-mktplace-menu__menu-carousel__item,
  .season-black-november .hm-mktplace-menu__footer--vertical,
  .season-black-november .hm-mktplace-menu__header,
  .season-black-november .hm-mktplace-search__container__header,
  .season-black-november .hm-mktplace-header__options,
  .season-black-november .hm-mktplace-menu__menu-content__section-subcategory-active {
    background-color: #000000 !important;
  }

  .season-header.black-november .season-header__logo-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-bottom: 8px;
  }

  .season-header.black-november .season-header__logo-right img {
    width: 48px;
    height: 48px;
  }

  .season-header.black-november .season-header__logo-right h6 {
    font-size: 21px;
    font-weight: bold;
    color: #E9FAEE;
  }

  .season-header.black-november .season-header__container {
    padding: 0;
  }

 .season-black-november .season-header .season-header__container__head__message__h1 {
    font-family: 'RobotCrush';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.96px;
    color: var(--brand-primary-darker);
    background: #F5CF67;
    padding: 0.25rem 0.625rem;
    margin-bottom: 0.25rem;
    width: fit-content;
  }

  .season-black-november .hm-mktplace-menu__menu-content__section .hm-mktplace-menu__purchases {
    background: #000000;
  }

  .season-header.black-november .season-header__container__head__logo__img,
  .season-header.black-november .season-header__container__head__logo__img__secondary {
    width: 100%;
    height: 94px;
  }

  .season-header.black-november .season-header__container__head__logo__img,
  .season-header.black-november .season-header__container__head__logo__img span {
    width: 122px !important;
    margin-right: 2rem;
  }

  .season-header.black-november .season-header__container__head__logo__img__secondary {
    display: none;
  }

  .season-black-november .hm-mktplace-header__search__container .hm-mktplace-search__container__title,
  .season-black-november .hm-mktplace-header__search__container .hm-mktplace-suggestions-list__title,
  .season-black-november .hm-mktplace-menu__link.categories,
  .season-black-november .hm-mktplace-menu__categories--mobile,
  .season-black-november .hm-mktplace-menu__categories--link,
  .season-black-november .hm-mktplace-menu__subcategories--link,
  .season-black-november .hm-mktplace-menu--categories-see-all,
  .season-black-november .hm-mktplace-menu--categories-back {
    color: #ffffff !important;
  }

  .season-black-november .hm-mktplace-header--not-flying .hm-search-close-button__icon-search,
  .season-black-november .hm-mktplace-header--not-flying .hm-mktplace-header__session__signin,
  .season-black-november .hm-mktplace-header--not-flying .hm-search-close-button__icon-close,
  .season-black-november .hm-mktplace-header--not-flying .hm-burger-button,
  .season-black-november .hm-mktplace-search__container__title,
  .season-black-november .hm-mktplace-menu__header--mobile {
    color: #ffffff !important;
  }

  .season-black-november .hm-mktplace-header--not-flying .hm-mktplace-header__session__signup:hover {
    background-color: #ffffff;
    color: #FE734F !important;
  }

  .season-black-november .season-header__container__head__logo__img__main {
    object-fit: contain !important;
  }

  .season-black-november .hm-mktplace-menu__categories:after, 
  .season-black-november .hm-mktplace-menu__subcategories:after { 
    background-image: none !important;
  }

  .season-black-november .filter-modal .results-filter-placeholder__title {
    margin-top: 2.25rem;
    margin-bottom: 1.2rem;
  }

  .season-black-november .filter-modal__footer,
  .season-black-november .filter-modal__header,
  .season-black-november .hm-mktplace-menu__categories li:not(:last-child):not(:first-child),
  .season-black-november .hm-mktplace-header .hm-mktplace-header__options:after,
  .season-black-november .hm-mktplace-menu__subcategories {
    border: none;
  }

  .season-black-november .season-header__container__head__logo {
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: flex-end;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0.6rem;
  }

  .season-header__container__head__logo  .season-header__container__head__logo__img {
    height: 40px;
    width: 200px;
  }
}

@media (max-width: 420px) {
  .season-black-november .season-header__container__head {
    margin-bottom: 0;
  }

  .season-header.black-november .season-header__container.results-searchbar {
    margin-left: 1rem;
  }

  .season-black-november .season-header {
    background-image: url(/s/static/images/black-november/banner-black-november-mobile.svg);
    background-repeat: no-repeat;
  }

  .season-black-november .results-page__black-november .page-container__content{
    padding: 0 3rem 1rem 2rem !important;
  }

  .season-black-november .filter-row__select .filter-row-option:last-child {
    margin-right: 1rem;
  }

  .season-header.black-november .season-header__container__head__secondary div {
    font-size: 16px;
    line-height: 17px;
  }

  .season-header.black-november .season-header__container__head__secondary {
    margin-bottom: 0.75rem;
  }

  .season-header.black-november .season-header__container__head__logo__img,
  .season-header.black-november .season-header__container__head__logo__img span {
    width: 240px !important;
  }

  .season-header.black-november .season-header__container__head__logo__img {
    height: 20px;
  }

  .season-header.black-november .season-header__container__head__logo__img span {
    height: 40px !important;
  }

  .season-header.black-november .season-header__container {
    padding: 2rem 0 3rem 0;
  }
}

@media (max-width: 890px) {
  .season-header.black-november .season-header__container.results-searchbar {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 790px) and (max-width: 890px) {
  .season-black-november .results-filter-placeholder {
    flex: auto !important;
    padding-right: 0.5rem !important;
  }
}

@media (min-width: 800px) {
  .season-black-november .season-header {
    background-size: auto!important;
  }

  .season-black-november .no-results {
    padding: 4rem 8rem !important;
  }

  .season-black-november .season-header__container.results-searchbar {
    display: flex;
    flex-direction: column;
    margin: inherit;
    margin-left: 2rem;
    margin: 0 auto;
  }

  .season-black-november .season-header__container__head {
    justify-content: flex-start;
  }

  .season-black-november .season-header__container__head__message__h1 {
    font-size: 1.5rem;
    padding: 0.5rem 1.25rem;
  }

  .season-black-november .page-container__content {
    top: 5rem !important;
  }

  .season-black-november .black-november-info__text {
    width: 600px;
  }

  .season-header.black-november .season-header__container__head__logo__img {
    width: 250px;
    height: 70px;
  }

  .season-black-november .season-header__container__head__logo__img__main {
    position: inherit !important;
  }

  .season-black-november .season-header__container__head__secondary {
    margin-left: 1.25rem;
  }

  .season-header.black-november .season-header__container__head__logo__img__secondary {
    margin-left: 1.25rem !important;
    max-width: initial !important;
    width: auto !important;
    margin-top: 0;
    display: block;
  }

  .season-header.black-november .season-header__container__head__secondary > span {
    overflow: initial !important;
  }

  .season-black-november .season-header__container__head__logo__img > span {
    position: inherit !important;
    display: initial !important;
  }
}

@media (min-width: 900px) {
  .season-black-november .season-header__container__head {
    justify-content: flex-start;
  }
}

@media (min-width: 1140px) {
  .season-black-november .season-header__logo {
    display: initial;
  }

  .season-header.black-november .category-search {
    max-width: 920px !important;
    margin-top: 0;
  }

  .season-header.black-november .season-header__container__head__logo__img {
    width: 324px;
  }

  .season-black-november .page-container__content {
    top: 6rem !important;
  }

  .season-black-november .season-header__container__head__logo {
    width: 320px;
  }

  .season-header.black-november .season-header__container__head__logo__img,
  .season-header.black-november .season-header__container__head__logo__img__secondary {
    max-height: 100%;
  }

  .season-header.black-november .season-header__container__head__logo__img__secondary {
    margin-left: 16px;
  }

  .season-header--cyber-week .season-header__container__head__logo {
    display: none!important;
  }

  .season-header--cyber-week .season-header__container__head__message__h1 {
    text-align: left;
  }

  .season-header.season-header--cyber-week {
    background-position: left!important;
  }

  .season-header.black-november .season-header__container__head__message__h1 {
    text-align: left;
    font-size: 1.5rem;
    padding: 0.5rem 1.25rem;
  }

  .season-header.black-november .season-header__container {
    padding: 0;
    width: 100%;
  }

  .season-header.black-november .season-header__logo-right img {
    margin: 0;
    margin-right: 16px;
    width: 48px;
    height: 48px;
  }

  .season-header.black-november .season-header__logo-right h6 {
    font-size: 21px;
    font-weight: bold;
    color: #E9FAEE;
  }
}

@media(min-width: 1400px) {
  .season-header.black-november .season-header__logo-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 36px;
    bottom: 35px;
  }

  .season-header.black-november .season-header__container {
    padding-top: 2.2rem;
    margin: 0;
  }

  .season-black-november .season-header .page-container__content {
    position: initial !important;
  }
}

.body-locked .season-black-november .body__content {
  background-color: var(--black) !important;
}

@media(min-width: 1920px) {
  .season-header {
    width: 1920px;
  }
}

