.black-november-banner {
  $self: &;
  display: flex;
  justify-content: space-between;
  background: url('/s/static/images/black-november/black-november-background.svg')
      no-repeat,
    #000000;
  background-size: cover;
  background-position: center center;
  display: flex;
  text-decoration: none;
  padding-right: 0px;
  align-items: center;
  height: 137px;
  margin-top: 4.5rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  &__bottom {
    margin-top: 4.5rem;
    background-color: var(--gray-100);
  }

  &__header {
    margin-top: 2rem;
    border-radius: 6px;

    @media (max-width: $mobile-content-max) {
      margin-top: 0.5rem;
    }

    .black-november-banner__percentage > span {
      height: 135px !important;
    }
  }

  &__percentage {
    flex: 1 0 40%;
    display: none;

    @media (min-width: $tablet-mid-1) {
      text-align: center;
      flex: 1 0 30%;
    }

    @media screen and (min-width: $desktop) {
      display: block;
    }

    & > span {
      overflow: inherit !important;
      margin-top: 0.25rem !important;
      width: auto !important;
      height: 150px !important;
    }

    &__number {
      width: 100%;
      display: block;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: flex-start;
    background-image: url('/s/static/images/black-november/black-november-results-mobile.png'),
      linear-gradient(
        193deg,
        rgba(244, 75, 40, 0.85) 8.5%,
        rgba(0, 0, 0, 0.12) 77.69%
      );
    padding: 1.5rem 1.2rem;
    height: auto;
    background-size: contain;
    background-position: right;
  }

  @media screen and (min-width: $tablet-mid-2) {
    background-size: cover;
    background-position: center right;
    padding: 0 2.5rem;
  }
}

.search__banner {
  &--black-november {
    padding: 2rem 4.5rem 0;
    position: absolute;
  }

  &__text {
    font-family: 'RobotCrush', sans-serif;
    font-style: normal;
    background: #f04e23;
    color: black;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 20px;
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    width: fit-content;

    &--light {
      background: #f5cf67;
      color: #c5310a;
    }
  }

  &__button {
    font-weight: bold;
    background: #f04e23;
    color: var(--white);
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    text-decoration: auto;

    &:hover {
      background: #c5310a;
    }

    @media (max-width: $mobile-content-max) {
      padding: 0.25rem 0.5rem;
    }
  }

  &__link {
    &:visited,
    &:hover,
    &:active,
    &:link {
      text-decoration: none;
    }
  }

  &__info {
    color: var(--white);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;

    span {
      font-weight: bold;
    }
  }

  &__highlight {
    padding: 0.75rem 0;
    width: 35%;

    @media (max-width: $mobile-content-max) {
      width: 70%;
    }

    @media (max-width: $mobile-m) {
      width: 75%;
    }
  }
}
