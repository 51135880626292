

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255, 0.7);
  z-index: 10000;
  transition: opacity 450ms;
  opacity: 1;
  pointer-events: none;
}

.profile-loading {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: .75rem 1.5rem;
  position: relative;

  &__fire {
    svg {
      width: 30px;
      height: auto;
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      @media (max-width: 720px) {
        width: 20px;
      }
    }

    svg path {
      fill: #f04e23;
    }
  }

  &__activity {
    animation-name: logo_spin;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 85px;
    height: 85px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 1.4px solid transparent;
    border-top-color: #f04e23;
    border-left-color: #f04e23;
    border-radius: 100%;

    @media (max-width: 720px) {
      height: 50px;
      width: 50px;
    }

    &--revert {
      animation-name: logo_reverse;
      width: 70px;
      height: 70px;

      @media (max-width: 720px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}

// KEYFRAMES ----------------------------------------------------------------------------------------------

@keyframes logo_spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logo_reverse {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(-360deg);}
}

