@font-face {
  font-family: 'RobotCrush';
  src: url('../../../s/static/fonts/robot_crush.woff') format('woff');
}

.body-black-november {
  $self: &;

	.season-blacknovember {
		background-color: var(--black);
		color: var(--white);

		.filter-row__title {
			text-align: center;
			color: var(--white);
			margin: 0;
			padding-top: 1.8rem;
      font-size: 1.75rem;
      font-weight: 800;
		}
	}

	&.season-black-november .hm-mktplace-menu__footer--vertical {
		background: var(--black);
	}

	&.season-black-november .hm-mktplace-menu__menu-content {
		.hm-mktplace-menu__menu-content__item path {
			fill: #FF9377;
		}

		.hm-mktplace-header__session__signin {
			border: 1px solid #FE734F;
		}

		.hm-mktplace-header__session__signup {
			color: var(--black);
			border-color: var(--white);
			background: var(--white);
		}

		.hm-suggestions-list__suggestion__link {
			background: #E5402B;
		}
	}

	.product-card-alt--black-november {
		background: var(--gray-900);

		&__content-top {
			color: var(--white)
		}

		&__author {
			color: var(--gray-300);
			font-weight: 400;
		}

		&__rating__total {
			color: var(--gray-300);
		}

		&__price {
			align-items: baseline;

      @media (min-width: 768px) {
        align-items: flex-end;
      }

			h3 {
				color: var(--white);
			}
		}

		.discount-banner {
			border-top-left-radius: 0.25rem;
			border-bottom-left-radius: 0.25rem;

			&__logo {
				width: 120px;
				margin: 0 0.25rem;
			}

			&__value {
				font-size: 14px;
				line-height: 19px;
				border-top-right-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}
		}
	}

	.product-card-alt--creator {
		padding-right: 0.5rem;
	}

	.black-november__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'RobotCrush', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    background-color: var(--black);
    color: var(--white);
    height: 42px;

		&--black {
			background: var(--gray-900);
   		color: var(--white);
		}

		span {
			margin: 0 0.5rem;
			font-size: 1.5rem;
			text-decoration: underline;
			color: #F5CF67;
		}
  }

	.black-november__progress {
		background: linear-gradient(to right, #6A1200 0%,rgba(106, 18, 0, 0.85), #ffb229 99%, rgba(207, 148, 43, 0) 100%);
		width: 30%;
		height: 12px;

		&--backwards {
			background: linear-gradient(to right, #f6b23a 0%, #6a1200, #6A1200 99%, #6a1200 100%);
		}

		&--full {
			width: 85%;
		}
	}

	.creator_bn {
		margin-bottom: 2.5rem;
		border-radius: 0.75rem;

		&, hot-card {
			background-color: var(--gray-900) !important;
		}

		hot-card {
			margin-bottom: 0 !important;
		}

		hot-card-body {
			padding: 0.75rem;
		}

		span {
			color: var(--white) !important;
		}

		.hot-button--tertiary {
			text-decoration: underline;
			font-weight: 700;

			&:hover {
				background-color: transparent !important;
			}
		}

		&_all {
			padding-left: 0;
		}

		.tag-container {
			margin-left: 0;
			margin-top: 0.25rem;
		}
	}

	.discount-banner--creator {
		max-width: max-content !important;
		border: none;

		.discount-banner__value {
			width: 100%;
			font-size: 10px;

			span {
				color: #C5310A !important;
			}
		}
	}

	&.body-locked {
		.body__content {
			background: inherit;
		}
	}

	.footer-resumed h3 {
		margin-top: 6rem;
	}

	.search-container--hide-banner {
		.search__banner.search__banner--discounts {
			height: auto;
      margin-top: 0;
      background-color: #000000;

			.page-container__content {
				height: auto;
			}
		}
	}

  .search__banner {
    height: auto !important;
  }

	.results__aux-bar {
		&__search,
		&__term {
			color: var(--white);
			font-size: 1rem;
		}

		&__term {
			font-weight: 800;
		}
	}

  .search__most-accessed, .search__black-november {
    background: var(--black);
		width: 100%;
		max-width: 1440px;

    .suggestions-list__tag {
			background: var(--white)!important;
			color: $red!important;
    }

    .search__section__title {
      color: var(--white);
			margin-bottom: 1.5rem;
			margin-top: 1rem;
      font-weight: 800;
      font-size: 1.25rem;
      margin-right: 1rem;

      @media (min-width: $desktop) {
        font-size: 1.75rem;
      }
    }

		.product-link {
			& > div:first-child {
				background: #181c1f;
			}
		}

		.slick-arrow-buttons {
			border: none;

			svg {
				color: #EF4E23;
			}

			&.slick-disabled {
				svg {
					color: var(--gray-200);
				}
			}
		}

		.search-container__label {
			font-family: 'Nunito Sans';
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 150%;
			width: 260px;
			height: 34px;
			color: #EF4E23;
			background:  var(--white);
			border: 1px solid  var(--white);
			box-sizing: border-box;
			border-radius: 4px;
		}
  }

	.search__most-accessed {
		h2 {
			width: 70%;
		}
	}

	.loading {
		display: none;
	}

	.search-container__black-november {
		max-width: 100%!important;

		.search__newbies:last-child {
      padding-bottom: 6rem;
    }
	}

	.search-container {
    padding: 0!important;

		.search__fashion {
			h2 {
				margin-top: 1rem;
			}

			.search__section__title {
				margin-top: 0;
			}
		}
  }

	.search_new,
	.search_fashion,
	.search_idiom {
		h2 {
			&.search__section__title {
				margin-top: 0;
			}
		}
	}

	.coupon-filter {
		margin-bottom: 3rem!important;
	}

  .search__category-search {
		max-width: 980px !important;

		&__title {
			font-family: 'Nunito Sans';
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 120%;
			color: var(--white)!important;
			margin-bottom: .5rem;
		}
	}

	.search__section__title--top {
		margin-top: 0!important;
	}

	.search__fixed-banners {
		margin-top: -4rem;

		.fixed-campaign-banner {
			margin-top: 2rem;
		}
	}

	.filter-row--large {
		display: flex;
    justify-content: center;
		background: var(--black);
		padding: 2rem;
    width: 100%;

		.filter-row {
			display: flex;
      flex-direction: column;

			&__select {
				width: 100%;
				padding: 0.25rem 0.2rem;
        display: flex;
        justify-content: center;
			}

			&-option {
				justify-content: center;
				width: 8rem;
				min-width: 8rem;
				height: 3.5rem;
				border-radius: 6.25rem;
				background: #E6E9ED;

				&:hover {
					outline: 3px solid #ff9377;
				}

				&:not(:last-child) {
					margin-right: 1.4rem;
				}

				&__image {
          height: auto;

					& > span {
						width: 30px !important;
						height: 30px !important;
					}

					img {
						width: 30px;
					}
				}

				&__label {
					font-style: normal;
					font-weight: bold;
					font-size: 1rem;
					line-height: 120%;
				}
			}

			&__label {
				font-family: 'Nunito Sans';
				font-style: normal;
				font-weight: 300;
				font-size: 32px;
				line-height: 120%;
				color: var(--white);
				margin-top: 0;

				&--hidden {
					display: none;
				}
			}
		}

		&--discount {
			margin-top: 2rem;
		}
	}

	.search__banner {
		&--black-november {
			padding: 2rem 4.5rem 0;
		}

		&__text {
			font-family: 'RobotCrush', sans-serif;
			font-style: normal;
			background: #E5402B;
			color: #FFF4D7;
			font-weight: 400;
			font-size: 2.5rem;
			line-height: 20px;
			padding: 0.75rem;
			margin-bottom: 0.25rem;
			width: fit-content;

			&--light {
				background: #FFF4D7;
				letter-spacing: 0.02em;
				font-size: 22px;
				color: #E5402B;
			}

			&:first-child {
				margin-bottom: 0.5rem;
			}
		}

		&__button {
			font-weight: bold;
			font-size: 1.125rem;
			background: #F04E23;
			color: var(--white);
			padding: 0.5rem 1rem;
			border-radius: 0.25rem;
			border: none;
			cursor: pointer;
			text-decoration: auto;
			min-width: 215px;
			max-width: 225px;
			text-align: center;

			&:hover {
				background: #F04E23;
			}

			@media (max-width: $mobile-l) {
				font-size: 0.75rem;
				padding: 0.5rem;
				min-width: 130px;
				max-width: 150px;
			}
		}

		&__link {
			&:visited, &:hover, &:active, &:link {
				text-decoration: none;
			}
		}

		&__info {
			color: var(--white);
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5rem;
			margin-bottom: 1rem;

			span {
				font-weight: bold;
			}
		}

		&__highlight {
			padding: 0.75rem 0;
		}
	}

	.search__category__banner {
		display: flex;
    justify-content: center;
		flex-direction: column;
    align-items: center;
		cursor: pointer;
		background-image: url(/s/static/images/black-november/black-november-banner-home.png);
		background-repeat: no-repeat;
    background-position: inherit;
		background-size: cover;
		background-color: #000000;
		height: 270px;
		width: 100%;

		&--cyber-week {
			background-color: #FE734F;
			background-image: url(/s/static/images/cyber-week/cyber-week-banner.png);
			background-position: left;
			background-size: contain;

			&__container {
				display: flex;
				align-items: center;
			}

			&__logo {
				width: 360px;
				margin: 0 2rem 2rem 0;
			}

			&__text {
				flex: 1;
			}

			.black-november-banner-large__top {
				&__content__text {
					color: var(--black);
					width: auto;
				}

				&__cta__button {
					background: var(--black);
				}
			}
		}

		.page-container__content {
			max-width: 980px !important;
		}
	}

	.filter-row {
		::-webkit-scrollbar {
			display: none;
		}
	}

	.search_black-november {
		padding-bottom: 4rem;
		width: 100%;
	}

	.black-november-banner-large__bottom__header__discounts {
		display: flex;
    justify-content: center;
		align-items: center;
	}

  .black-november-banner-large__icon {
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    border-radius: 6.25rem;
    background: #191C1F;
    display: flex;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .black-november-card {
    background-color: var(--gray-900);
    border-radius: 6px;

    .product-card {
			&__title,
			&__rating,
			&__price {
      	color: var(--white);
			}

			&__author,
			&__rating__total {
				color: var(--gray-300);
			}

			&__prices {
				margin-top: 1rem;
			}
    }

		hot-card-body {
			border-radius: 0px 0px 6px 6px;
		}

		.product-image-wrapper {
			border: 2px solid;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom: 0;
		}

    &:hover {
			background-color:  var(--gray-900);

			.product-card__title {
				color: var(--white);
			}
    }
  }

	.search-container {
		&__banner-top {
			display: flex;
			justify-content: center;
			align-items: center;
			background-image: url(/s/static/images/black-november/banner-products-black-november.svg);
			background-position: initial;
			background-size: cover;
			background-repeat: no-repeat;
			background-color: #000000;
			width: 100%;
			height: 150px;
		}

		&__button {
			height: 44px;
			background: var(--white);
			color: $red;
			text-decoration: auto;
			box-shadow: 0px 0px 56px -8px rgba(239, 78, 35, 0.6);
			border-radius: 4px;
		}

		&__label {
			padding: .7rem 1rem 1rem .5rem;
			font-style: normal;
			font-weight: 600;
		}
	}

	.results-filter-placeholder {
		margin-top: 1rem;
	}

	.hm-mktplace-menu__content,
  &.season-black-november .hm-mktplace-search__container--fullscreen,
  .hm-mktplace-header__search__container--open,
	.hm-mktplace-menu__header--user {
    background-color: var(--black) !important;
  }

  .hm-mktplace-menu__header__icon-close,
  &.season-black-november .hm-mktplace-menu__link span,
  .hm-mktplace-menu__menu-content__item--highlighted,
  .hm-mktplace-menu__menu-content__item,
  &.season-black-november .hm-mktplace-search__container h2,
  .hm-mktplace-search__container__close,
	.hm-mktplace-menu__header--user .hm-mktplace-menu__header__label {
    color: var(--white) !important;
  }

  .hm-mktplace-menu__footer__link--signin {
    color: var(--white) !important;
    border-color: var(--white) !important;
  }

	.hm-mktplace-header--search-open {
		.hm-mktplace-header__container {
			background-color: #181c1f!important;
		}
	}

	.hm-mktplace-header__search__container--open {
		background-color: #181c1f!important;

		.hm-mktplace-search__container__title {
			color: var(--white);
		}
	}

	&.body-cyber-week {
		& > div:not(.search__section__black-november),
		.search__most-accessed, .search__black-november,
		.search__category-search__black-november,
		.search-container__filter,
		.hm-mktplace-header__container,
		.hm-mktplace-menu__content,
		.hm-mktplace-search__container--fullscreen,
		.hm-mktplace-header__search__container--open,
		.hm-mktplace-header--search-open .hm-mktplace-header__container {
			background: var(--gray-600);
		}

		.hm-mktplace-header__search__container--open,
		.hm-mktplace-header--search-open .hm-mktplace-header__container {
			background: var(--gray-600)!important;
		}

		.search-container__banner-top {
			background-color: #FE734F;
			background-image: url(/s/static/images/cyber-week/cyber-week-section.png);
		}

		.slick-arrow-buttons {
			svg {
				color: inherit;
			}
		}

		.search-container__filter {
			.filter-row-option {
				&:first-child {
					background-image: url(/s/static/images/cyber-week/banner-filter-card-1.png);
				}

				&:nth-child(2) {
					background-image: url(/s/static/images/cyber-week/banner-filter-card-2.png);
				}

				&:nth-child(3) {
					background-image: url(/s/static/images/cyber-week/banner-filter-card-3.png);
				}

				&:nth-child(4) {
					background-image: url(/s/static/images/cyber-week/banner-filter-card-4.png);
				}

				&:nth-child(5) {
					background-image: url(/s/static/images/cyber-week/banner-filter-card-5.png);
				}
			}
		}
	}

	@media (min-width: $desktop-l) {
		.search__category__banner {
			background-image: url(/s/static/images/black-november/banner-home-search.svg);
			background-position: inherit;

			&--cyber-week {
				background-image: url(/s/static/images/cyber-week/cyber-week-banner.png);
				background-position: left;
				background-size: contain;

				.black-november-banner-large {
					&__top {
						&__content__logo {
							display: none;
						}
					}
				}
			}
		}

		.search__banner-wrapper {
			.search__banner-image__wrapper {
				span {
					border-radius: 12px 12px 0px 0px;
				}
			}
		}
	}

	@media screen and (min-width: $desktop) and (max-width: $desktop-m) {
		.search__banner.search__banner--discounts {
			.page-container__content {
				padding: 0;
			}
		}
	}

	@media (max-width: $desktop) {
		.filter-row {
			&__label {
				font-weight: normal;
				line-height: 150%;
			}

			&-option {
				&:not(:last-child) {
					margin-right: .5rem!important;
				}
			}

			&__select {
				display: flex;
				width: 80%;
				overflow: scroll;
			}
		}

		.search__banner {
			&--black-november {
				padding: 1rem;
			}

			&__text {
				font-size: 20px;
				color: #E5402B;
				background: #FFF4D7;
				padding: 0.5rem;

				&:first-child {
					margin-bottom: 0.25rem;
				}

				&:last-child {
					margin-bottom: 1rem;
				}
			}

			&-image__wrapper {
				span {
					height: 200px !important;
					border-radius: inherit !important;
				}
			}

			&__highlight {
				padding: 0.25rem 0;
			}
		}

		.search__category__banner {
			&--cyber-week {
				background-image: none;

				&__container {
					flex-direction: column;
				}

				&__logo {
					width: 260px;
					margin: 0;
				}

				&__text {
					text-align: center;
				}
			}
		}
	}

	.custom-coupon-filter {
		.selectable-list-counter__item__counter {
			display: none;
		}
	}

	.mobile-filters .button-iconed__label {
		color: var(--white) !important;
		font-weight: 700 !important;
		font-size: 0.875rem !important;
    font-style: normal;
	}

	@media (max-width: $mobile-m) {
		.search__category__banner {
      min-height: 240px;
			height: auto;
			padding: 1rem 0;
			background-position: bottom;

      &--cyber-week {
        &__container {
          min-height: 208px;
        }
      }

			& > div {
				width: 80%;
			}

			.page-container__content {
				padding: 0;
			}
		}

		.search__section__title {
			margin-bottom: .5rem!important;
		}

		.search_idioms {
			padding-bottom: 1rem!important;
		}

		.search-container__black-november {
			.search__newbies:last-child {
				padding-bottom: 0;
			}
		}

		.search-container__filter {
			padding-top: 0rem;

			.filter-row {
				&__label {
					margin-top: 2rem;
					padding-bottom: .5rem;
					width: auto;
					text-align: left;
					margin-left: -1rem;
				}
			}

      @media (min-width: $mobile) {
        min-height: 180px;
      }
		}

		.suggestions-list__tag__body {
			font-size: 12px!important;
		}

		.search-container {
			&__banner-top {
				background-position: right;
			}
		}
	}

	@media (max-width: $mobile-l) {
		.filter-row--large {
			&__label {
				width: 100%;
				max-width: 400px;
				margin-left: 1.5rem!important;
			}
		}

		.mobile-filters {
			padding-bottom: 1rem;
			border-bottom: 1px solid var(--gray-700) !important;
		}

		.product-card-alt {
			&__price {
				min-height: auto;
				margin: 0.25rem 0;
			}
		}

		.creator_bn {
			&_container  {
				justify-content: initial;
				align-items: start !important;
			}

			&_image {
				width: 72px !important;
				padding-top: 0.25rem;

				.image-avatar {
					border-radius: 0.375rem;
				}
			}

			&_all svg {
				height: 12px !important;
				width: 12px !important;
			}
		}

		.black-november {
			&__time {
				margin: 2rem 0 1rem 0;
			}

			&__progress {
				width: 90%;
			}

			&__banner {
				font-size: 0.75rem;

				span {
					font-size: 1rem;
				}
			}
		}

		.black-november-info {
			width: 100% !important;
			margin-top: 0.5rem;

			&__text {
				margin-top: 6rem;
			}

			div {
				font-size: 1.5rem;
				width: fit-content;
			}

			.black-november__progress {
				width: 100%;

				&--mobile {
					padding-top: 0.5rem;
				}
			}
		}
	}

	@media (max-width: $tablet-min) {
		.filter-row--large {
			padding: 0 0 1.5rem;

			.filter-row {
				&__select {
					display: flex;
					padding: 0.125rem 1rem 0.125rem 0;
					overflow: scroll;
          justify-content: flex-start;
				}

				&-option {
					min-width: 92px !important;
					height: 38px !important;

					&:hover {
						outline: 2px solid #ff9377;
					}

					&:first-child {
						margin-left: 1rem;
					}
				}
			}
		}

		@media (max-width: 390px) {
			.filter-row--large {
				.filter-row {
					&-option {
						&:not(:last-child) {
							margin-right: 0.875rem !important;
						}
					}
				}
			}
		}

		.season-blacknovember {
			.filter-row__title {
				font-size: 1.25rem;
				padding: 2rem 0 1rem 1rem;
			}
		}
	}

	.load-more h3 {
		display: none;
	}

	@media (max-width: $tablet) {
		.search-container {
			&__label {
				font-size: 12px;
			}
		}
	}

	@media (max-width: $tablet-content-max) {
		.search-container {
			.search__banner:first-child {
				height: 175px !important;
			}
		}
	}

	.page-container__content {
		padding: 0;
	}
}

.product-card-alt--black-november {
  &:hover {
    outline: 5px solid #FF9377;
  }

  .product-card__price--discount {
    flex-direction: column;
    align-items: flex-end;

    >span {
      display: flex;
      margin-top: 0.5rem;
    }
  }

  .product-card-alt {
    &__title,
    &__rating,
    &__description,
    &__price,
    &__author {
      color: #E6E9ED
    }

    &__rating__total {
      color: #707780
    }
  }

  .product-card__price rect,
  .product-card-alt__price rect {
    fill: var(--gray-800) !important;
  }

  @media (max-width: $tablet) {
    .product-card-alt__info {
      display: flex;
      flex-direction: column;
    }

    .product-card__price--discount {
      order: 1;
      align-items: flex-start;
    }

    .product-card-alt__price {
      order: 2;
      margin-top: 0rem;
      margin-bottom: 0.25rem;
      min-height: auto;
      align-items: flex-start;
    }

    .discount-banner {
      order: 3;
    }
  }
}
