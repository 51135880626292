.bubble-set {
  h2 {
    margin-bottom: $list-gutter;
  }
  .bubble-item {
    display: inline-block;
    border: 1px solid $grey-3;
    color: $grey-3;
    margin: 0 $list-gutter $list-gutter 0;
    border-radius: 30px;

    &:hover {
      background: $grey-3;
      color: $grey-1;
    }

    a {
      padding: 5px 20px;
      display: block;
      text-decoration: none;
    }
  }
}
