

.link-arrow {
  color: inherit;
  text-decoration: none;
  align-items: center;
  display: inline-flex;
  cursor: pointer;

  .link-arrow-text {
    position: relative;
    z-index: 2;
  }
  .link-arrow-icon {
    padding: 10px;
    display: inline-block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    margin-left: -5px;
    z-index: 0;
    transition: all $ease $timing-5;
  }

  &.arrow-left {
    .link-arrow-text{
      order: 2;
    }
    .link-arrow-icon{
      order: 1;
    }
  }
}
