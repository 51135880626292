.content-loader {
  &--container {
    display: block;
    overflow: hidden;
    position: relative;
  }

  &--loader {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &--shine {
    animation: shine 2500ms infinite linear;
    background-color: var(--gray-200);
    background-image:
      linear-gradient(
        90deg,
        var(--gray-200) 0,
        var(--gray-100) 45%,
        var(--gray-200) 90%
      );
    background-size: 200%;
    border-radius: var(--border-radius);
    display: block;
    height: 100%;
    width: 100%;
  }
}

@keyframes shine {
  0% {
    background-position: 100%;
  }

  40% {
    background-position: -100%;
  }

  100% {
    background-position: -300%;
  }
}
