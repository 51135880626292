.results {
  &-button-group {
    border-color: var(--gray-900) !important;
    color: var(--gray-900) !important;
    background: var(--white) !important;
    text-transform: capitalize;
    &--active {
      color: var(--white) !important;
      background: var(--gray-900) !important;
    }
  }

  &-container {
    .search-by {
      margin-bottom: 24px;
      &__results-for {
        font-size: 14px;
        color: #343a42;
      }

      &__total-products {
        font-size: 12px;
        letter-spacing: -0.26px;
        color: #6b747f;
      }
    }

    .mobile-filters {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      &__filter {
        padding-left: 0.25rem;
      }

      @media (max-width: $tablet) {
        margin: 0 0.25rem 1rem 0.25rem;
      }
    }

    .results-filter-placeholder__title {
      .hot-collapse__item:hover {
        background-color: transparent !important;
      }
    }

    .info-header {
      max-width: 100%;
      text-align: center;

      &__total-products {
        font-size: 12px;
        letter-spacing: -0.26px;
        color: #6b747f;
        flex: auto;
      }

      &__filter {
        flex: 1 0 50%;
        text-align: center;
        .button-iconed {
          display: inline-flex;
          width: 56px;
          height: 18px;
          padding: 0;
          border: 0;
          border-radius: 0;
          &__label {
            white-space: nowrap;
            font-size: 0.75rem;
            color: var(--gray-800);
            font-weight: normal;
            line-height: 150%;
          }
          &__marker {
            font-size: 0.75rem;
            color: #343a42;
            margin-right: 0.125rem;
          }
          &__icon {
            font-size: 0.75rem;
            color: $color-primary;
          }
          &:hover {
            background: transparent;
          }
        }

        svg {
          color: var(--blue);

          width: 12px;
          height: 12px;
        }
      }
    }

    .results-section {
      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        min-height: 40px;

        &__title {
          font-size: 32px;
          color: $grey-14;
          font-weight: normal;
          flex: 1;
        }

        &__discount-filter-top {
          text-align: right;
          width: 100%;
          position: absolute;
          overflow: auto;

          // hiding scrollbar visually
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }

          .filter-row__select {
            display: flex;
            flex-wrap: nowrap;
          }
        }
      }
    }

    .results-for {
      padding-left: 24px;
    }

    .content-placeholder {
      text-align: center;
      max-width: 100%;
      width: 100%;

      &--ltr {
        .results__aux-bar {
          justify-content: left;
        }
      }

      &--recommendations {
        width: 70%;

        @media (min-width: $desktop) {
          width: 75%;
        }

        @media (max-width: $desktop) {
          width: 65%;
        }

        .search__suggestions {
          .no-results__most-wanted__title {
            font-size: 1.2rem;
            margin: 2rem 0 0.5rem 0;
          }
        }

        .results__aux-bar {
          justify-content: flex-end;
        }

        @media (max-width: $desktop) {
          .embla {
            padding-left: 0;

            &__container__wrapper {
              margin: 0;

              .embla__slide__size_4 {
                min-width: 24%;
              }
            }
          }
        }

        @media (min-width: 600px) and (max-width: $desktop-m) {
          .embla {
            padding-left: 0;
          }
        }
      }
    }

    .free-fire {
      background: #f7f9fa;
      margin: 0 -16px;
      padding: 16px;
      border-radius: 6px;
      margin-top: 24px;

      .h2-free-fire {
        font-weight: 300;
        font-size: 20px;
        line-height: 120%;
        color: #32363b;
        margin-bottom: 24px;
        text-align: left;
      }
    }

    .results-filter-placeholder {
      padding-right: 1.5rem;
      padding-top: 1rem;

      :first-child {
        margin-top: 0;
        border-top: 0;
      }

      &__title {
        margin-top: 2.25rem;
        margin-bottom: 1.2rem;
        border-top: 1px solid var(--gray-200);
        min-height: 47px;

        &__type {
          color: var(--gray-900);
          font-size: var(--text-3);
          font-weight: var(--font-weight-bold);
          line-height: 1.875rem;
        }

        &__icon {
          margin-left: 0.5rem;
        }
      }

      &-black-november {
        .results-filter-placeholder__container {
          .results-filter-placeholder__title {
            font-size: 16px;
            line-height: 27px;
            letter-spacing: -0.25px;
            color: #000000;
          }
        }

        .coupon-filter {
          margin-bottom: 1rem;
        }

        &__button {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          background: #ef4e23;
          color: #ffffff;
          border: none;
        }
      }

      @media (min-width: $tablet-mid-2) {
        .results-filter {
          &-rating {
            min-height: 120px;
          }

          &-category {
            min-height: 314px;
          }

          &-format {
            min-height: 120px;
          }

          &-payment {
            min-height: 208px;
          }

          &-language {
            min-height: 120px;
          }

          &-ordering {
            min-height: 67px;
          }
        }
      }

      .category-filter {
        margin-bottom: 2.4rem;
      }

      .product-format-filter {
        margin-bottom: 2.4rem;
      }

      .payment-format-filter {
        margin-bottom: 3rem;
      }

      .custom-coupon-filter-container {
        margin-bottom: 48px;
      }
    }

    .body-wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 48px;

      @media (min-width: $tablet) {
        flex-direction: row-reverse;
      }

      .results-filter-placeholder {
        flex: 0 0 300px;

        @media (max-width: $desktop-m) {
          flex: 0 0 260px;
        }
      }
    }

    .product-list {
      margin-bottom: $tablet-list-gutter * 2;
    }

    .load-more {
      margin-top: 2.5rem;
      min-width: 197px;
    }

    .black-november-banner-link {
      text-decoration: none;
    }

    .additional-products {
      margin-top: 108px;
      &__title {
        text-align: left;
        font-size: 32px;
        margin-bottom: 32px;
        font-weight: normal;
      }
    }

    .black-november-banner-small {
      margin-top: 48px;
    }

    .product-related {
      margin-top: 13.25rem;

      &__title {
        text-align: left;
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 2rem;
      }
    }

    @media screen and (min-width: 797px) {
      .results-section {
        margin-top: 2rem;

        &__header {
          min-height: auto;
          &__discount-filter-top {
            text-align: right;
            width: 100%;
            margin: 0px;
            padding: 0px;
            position: relative;
          }
        }
        &__header {
          margin-bottom: 32px;
          &__title {
            padding-right: 32px;
          }
        }
      }
    }

    @media (max-width: 800px) {
      .results-filter-placeholder {
        display: none;
      }
    }

    @media screen and (min-width: $tablet-mid) {
      .body-wrapper {
        margin-bottom: 40px;
      }

      .product-list {
        margin-bottom: $tablet-list-gutter * 2;
      }
    }

    @media screen and (min-width: 683px) and (max-width: 1044px),
      screen and (min-width: 1044px) and (max-width: 1359px) {
    }

    @media screen and (max-width: 1024px) {
      padding: 24px 0;
      width: 100%;
    }

    @media (min-width: 1025px) {
      padding: 24px 0;
      width: 100%;
    }

    @media (max-width: 599px) {
      .results-for {
        padding-left: 0px;
      }

      .list-content {
        justify-content: center;

        .card {
          max-width: 100%;
          margin: 24px 0;
        }
      }
    }
  }

  &__divider {
    width: 0.0625rem;
    height: 1rem;
    background-color: var(--gray-300);
    margin-right: 0.625rem;
  }

  &__aux-bar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: 66px;
    gap: var(--spacer-4);

    @media (max-width: $mobile-l) {
      align-items: baseline;
      margin-left: 0.25rem;
    }

    &--horizontal {
      justify-content: right;
    }

    &__search {
      font-size: 1.5rem;
      font-weight: 300;
      color: var(--gray-500);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      width: 450px;
      text-align: left;

      &__black-november {
        text-align: initial;
        margin-left: 1rem;
      }
    }

    &__term {
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--gray-900);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media (max-width: $tablet-mid) {
      &__search {
        font-size: 1rem;
      }

      &__term {
        font-size: 1rem;
      }
    }

    @media (min-width: $tablet-mid) and (max-width: $tablet-max) {
      &__search {
        font-size: 1.2rem;
      }

      &__term {
        font-size: 1.2rem;
      }
    }
  }

  &__search-term {
    font-size: 1rem;
    font-weight: 300;
    line-height: 120%;
    color: var(--gray-500);

    :nth-child(2) {
      font-weight: bold;
      color: #282c2f;
      margin-left: 0.25rem;
    }

    @media (max-width: $tablet-max) {
      margin: 0 0 1rem 0.25rem;
    }
  }
}
