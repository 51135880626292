.ordering-filter {
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;

  &__button {
    color: var(--blue);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    transition: all $ease $timing3;
    padding: 0;
    font-size: .75rem;
    font-weight: bold;
    line-height: 150%;
    color: var(--gray-800);
    background-color: transparent;
    border: none;

    &-text {
      font-size: .75rem;
      font-weight: normal;
      white-space: nowrap;
      margin-right: .25rem;
    }

    svg {
      width: 12px;
      margin-left: .25rem;
      color: var(--blue);
    }

    svg {
      width: 12px;
    }

    @media (min-width: $tablet) {
      padding: 8px 16px;
    }
  }

  &__modal-header__icon {
    width: 20px;
  }

  &__icon {
    width: 12px;
  }

  &__options {
    padding: 20px 16px;
    max-width: 220px;
    height: 196px;
    list-style: none;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    z-index: 2;
    background-color: $white;
    margin-top: 34px;
    opacity: 0;
    pointer-events: none;
    transition: all $ease $timing3;

    &-item {
      font-size: 0.8rem;
    }

    &--horizontal {
      height: auto;

      .ordering-filter__options-item {
        &:hover {
          text-decoration: none;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 70%;
      width: 0;
      height: 0;
      border: 11px solid transparent;
      border-bottom-color: var(--white);
      border-top: 0;
      margin-left: -19px;
      margin-top: -19px;
    }

    &-active {
      opacity: 1;
      pointer-events: auto;
    }

    &-item {
      width: 100%;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        color: var(--blue);
        font-weight: bold;
      }
    }
  }

  &__box {
    &-shadow {
      &-active {
        width: 220px;
        height: 196px;
        background: #FFFFFF;
        position: absolute;
        margin-top: 39px;
        pointer-events: none;
        z-index: 1;
        box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2), 0px 6px 10px rgba(112, 119, 128, 0.14), 0px 1px 18px rgba(112, 119, 128, 0.12);
        border-radius: 4px 0px 4px 4px;

      }
    }
  }

  &__modal {
    &-mobile {
      position: fixed;
      top: 0;
      left: 0;
      background-color: white;
      width: 100%;
      height: 100%;
      z-index: 1000;
      pointer-events: auto;
      transform: translateX(120%);
      transition: transform $ease $timing-5;

      &.active {
        transform: translateX(0);
      }

      @media (min-width: $tablet) {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 16px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #979797;
        opacity: 0.1;
        left: 0;
        bottom: 0;
      }

      h2 {
        font-size: 20px;
        font-weight: 500;
        color: $color-primary;
      }

      &__icon {
        font-size: 20px;
      }
    }

    &-list {
      margin-left: 1.75em;
      padding: 32px 16px;
      &__item {
        margin-bottom: 24px;
        &-input {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }

        &-label {
          position: relative;
          margin-bottom: 0;
          font-size: 16px;

          &:before {
            transition: background-color .5s cubic-bezier(.2,1,.2,1),border-color .5s cubic-bezier(.2,1,.2,1),box-shadow .5s cubic-bezier(.2,1,.2,1),-webkit-box-shadow .5s cubic-bezier(.2,1,.2,1);
            -webkit-box-shadow: 0 0 0 1px #dfe2e6;
            box-shadow: 0 0 0 1px #dfe2e6;
            content: '';
            display: block;
            height: 1rem;
            left: -1.75rem;
            pointer-events: none;
            position: absolute;
            top: .1rem;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 1rem;
            border-radius: 50%;
          }

          &:after {
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"%3E%3Ccircle r="3" fill="%23fff"/%3E%3C/svg%3E');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 50% 50%;
            content: '';
            display: block;
            height: 1rem;
            left: -1.75rem;
            position: absolute;
            top: .1rem;
            width: 1rem;
          }
        }

        .ordering-filter__modal-list__item-input:checked~.ordering-filter__modal-list__item-label:before {
          background-color: #5981E3;
          box-shadow: 0 0 0 1px rgba(89,129,227, 0.50);
          color: #fff;
        }

        .ordering-filter__modal-list__item-input:checked~.ordering-filter__modal-list__item-label {
          color: var(--blue);
          font-weight: bold;
        }
      }
    }
  }
}
