.checkbox {
  $self: &;
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;

  &--va-top {
    align-items: baseline;
  }

  &__va-middle {
    align-items: center;
  }

  &__check {
    box-sizing: border-box;
    position: relative;
    border: 1px solid #6b747f;
    border-radius: 3px;
    width: 12px;
    height: 12px;
    min-width: 12px;
    max-width: 12px;
  }

  &__checked {
    #{$self} {
      &__check {
        background: #767f8c;
        border-color: #767f8c;
        
      box-shadow:inset 0px 0px 0px 1px #FFFFFF;
        &__mark {
          opacity: 1;
        }
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      #{$self}__label {
        color: #171717;
      }
    }
  }
}
