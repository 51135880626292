// KEYFRAMES ----------------------------------------------------------------------------------------------

@keyframes path {
    0% {
        transform: translateY(500%);
    }

    100% {
        transform: translateY(-500%);
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes fade {
    0%,
    100% {
        opacity: 0.65;
    }

    50% {
        opacity: 1;
    }
}


@keyframes logo_spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logo_reverse {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(-360deg);}
}

