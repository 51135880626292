.season-banner {
  width: 100%;
  margin: 24px 0 0 0;
  background-repeat: no-repeat;
  padding: 29px 16px;

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  &__cta {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    text-decoration: auto;

    &-label {
      text-align: center;
      margin-top: 32px;
      background-color: $white;
      color: $black;
      border-radius: 6px;
      cursor: pointer;
      border: 1px solid transparent;
      text-decoration: none;
      transition: color $timing $ease;

      &:hover {
        color: $black;
        border: 1px solid $black;
        background-color: transparent;
      }

      width: 100%;
      padding: 12px 20px;
    }

    &-text {
      flex-direction: column;
      justify-content: center;
      display: flex;
      margin: 5px 0 24px;
    }

    &__button {
      background: $white;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: normal;
      line-height: 150%;
      border-radius: 4px;
      display: inline-block;
      white-space: nowrap;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-top: 1rem;
      cursor: pointer;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: $white;
    word-break: break-word;
    margin: 0;
  }

  &__highlight {
    display: flex;
    align-items: center;

    &-title {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 65px;
      color: #E9FAEE;
    }

    &-image {
      width: 60px;
      margin-right: 1rem;
    }

    margin-bottom: 12px;
  }

  &__products {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0 -24px;
    padding: 0;

    .card-wrapper {
      margin-right: 16px;
      position: relative;
      &:last-child {
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: -2rem;
          width: 2rem;
          height: 1px;
        }
      }
    }

    .product-card {
      background-color: $white;
      max-width: 280px;
    }

    .product-card-mini {
      background-color: $white;
      max-width: 300px;
      height: 100%;

      .product-image-wrapper {
        height: 100%;
      }

      .product-image {
        height: 100%;
        min-width: 145px;
        object-fit: fill;
      }

      &__footer {
        margin-bottom: 8px;
      }

      &__rating {
        margin: 8px 0;
      }

      &__content-wrapper {
        padding: 0 12px 0 16px;
      }
    }
  }

  &__slider {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 24px;
    padding-right: 24px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: $desktop) {
    margin: 65px 0 0 0;
    padding: 60px 0 60px 0px;
    &__content {
      padding: 0 0 0 60px;
      margin-bottom: 48px;
    }

    &__cta {
      justify-content: flex-start;
      margin-bottom: 1.5rem;

      &-label {
        width: 180px;
        padding: 8px 20px;
      }

      &__description {
        font-size: 22px;
        line-height: 28px;
      }

      &__button {
        font-size: 16px;
      }

      &-text {
        margin: 24px 0;
        padding-right: 60px;
        flex: 0 0 44%;
        justify-content: flex-start;
      }
    }

    &__description {
      font-size: 20px;
      line-height: 28px;
    }

    &__highlight {
      flex: 0 0 48.5%;
      display: flex;
      justify-content: center;

      &-title {
        margin: 0;
        font-size: 48px;
      }

      &-image {
        width: 80px;
        margin-right: 1rem;
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }

    &__products {
      margin: 0px;
      margin-right: 36px;
      padding: 0 0 0 24px;
      .card-wrapper {
        margin-right: 32px;
        &:last-child {
          margin-right: 0;

          &:after {
            content: none;
          }
        }
      }
    }

    &__slider {
      width: 100%;
      overflow: auto;
    }
  }

  @media screen and (min-width: $max-content+1) {
    border-radius: 6px;
  }

  &.viver-bem {
    .season-banner__highlight-image {
      width: 196px;
    }
    @media (min-width: $desktop) {
      .season-banner__highlight-image {
        width: 448px;
      }
    }
  }

  &.cozinha-na-pratica {
    .season-banner__cta {
      align-items: center;
    }

    .season-banner__highlight-image {
      width: 196px;
    }
    @media (min-width: $desktop) {
      .season-banner__highlight-image {
        width: 300px;
      }
    }

    background-size: 100% 100% !important;
    background-position: top right !important;

    @media (min-width: $tablet) {
      background-size: 100% 45% !important;
      background-position: left right !important;
    }
  }
}
