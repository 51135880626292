.category {
  &__section {
    padding-top: 24px;

    &__title {
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      margin-top: 1rem;
      line-height: 120%;
    }

    &__suggestions {
      padding-bottom: 24px;
      margin-top: 16px;
      width: 100%;
      display: flex;
      justify-content: center;

      .suggestions-list {
        padding-left: 24px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &__search {
      padding-top: 3rem;
      margin-bottom: 24px;

      .carousel__background-bar {
        &__left,
        &__right {
          background: $white;
        }
      }
    }

    &__producer {
      .carousel__background-bar {
        &__left,
        &__right {
          background: $grey-2;
        }
      }
    }

    &__newbies {
      .carousel__background-bar {
        &__left,
        &__right {
          background: $white;
        }
      }

      .embla__container {
        .card-wrapper:first-child {
          margin-left: 0;
        }
      }
    }

    &__producer,
    &__newbies  {
      padding-top: 1rem;
      padding-bottom: 1.5rem;
    }

    &__products {
      padding: 0 0 1.5rem;

      .carousel__background-bar {
        &__left,
        &__right {
          background: $grey-2;
        }
      }

      .results-container {
        padding: 0px;

        .results-section {
          @media (max-width: $mobile-l) {
            margin-left: 0.5rem;
          }

          .results__aux-bar {
            flex-direction: inherit;
          }
        }

        .ordering-filter {
          padding: 0 0 0.5rem;

          &__options {
            padding: 20px 0.5rem 1rem 1rem;
            border-radius: 4px 0px 4px 4px;
            
            &-active {
              box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2), 0px 6px 10px rgba(112, 119, 128, 0.14), 0px 1px 18px rgba(112, 119, 128, 0.12);
            }

            &--horizontal {
              max-width: 320px;
              margin-top: 27px;
            }

            &:after {
              left: auto;
              right: 1rem;
            }

            &-item {
              &:not(:last-child) {
                margin-bottom: 1rem;
              }
            }
          }

          .selectable-list-counter__item--large {
            margin-bottom: 0;
          }

          &__button--horizontal {
            padding: 0 1rem;
            border-right: 1px solid #C9CED4;
          }
        }
      }

      .results-section__header { 
        margin-bottom: 0;

        &__title {
          margin-bottom: -1rem;
          font-style: normal;
          font-weight: 300;

          @media (max-width: $tablet) {
            font-size: 1rem;
            font-weight: 400;
            line-height: 150%;
            margin: 1rem 0 1rem 1rem;
          }
        }
      }

      .mobile-filters {
        @media (max-width: $tablet) {
          margin: 0 1rem 1rem 1rem;
        }
      }
    }
  }

  &-container {    
    &__banner {
      margin-bottom: 48px;
      &__slider {
        margin-top: 24px;
      }
    }
    .slick {
      $slick: &;
      &-track {
        display: flex;
      }

      &-list {
        margin-left: -16px;
        margin-right: -16px;
      }

      &-slide {
        padding: 0 16px;
        flex: 1;
      }

      &-arrow-buttons {
        #{$slick}-disabled {
          cursor: not-allowed;
          opacity: 0;
          pointer-events: none;
        }

        @media (max-width: 1024px) {
          opacity: 0;
        }
      }
    }

    &__banner {
      .banner-central {
        @media (min-width: $mobile) {
          margin: 0 0 16px 0;
        }

        @media (min-width: $tablet) {
          margin: 0 0 24px 0;
        }

        @media (min-width: $desktop) {
          margin: 0 0 48px 0;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    &__section {
      &__suggestions {
        .page-container__content {
          max-width: 100%;
          padding-right: 0;
          padding-left: 0;
          .suggestions-list {
            padding: 0 16px;
          }
        }
      }

      &__title {
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%;
        margin: 1rem 0 1rem 1rem;
      }
    }

    .results-section__header__title {
      font-size: 1rem;
      font-weight: 400;
      line-height: 150%;
      margin: 0 0 1rem 1rem;
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    &__section {
      &__title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        line-height: auto;
      }

      &__suggestions {
        .suggestions-list {
          padding-left: 0px;
        }
      }

      &__bestSellers {
        padding-bottom: 64px;
      }

      &__producer,
      &__newbies,
      &__products {
        padding: 1rem 0 1.5rem;
      }

      &__products {
        border-top: solid 1px $grey-2;

        .results-container {
          padding: 0px;
        }
        .results-section__header__title {
          font-size: 32px;
        }
      }
    }
  }

  &__banner {
    .page-container__content {
      @media (min-width: $mobile) {
        padding: 0;
        max-width: 100%;
      }

      @media (max-width: 1280px) and (min-width: 990px) {
        padding: 0px 16px;
      }

      @media (min-width: $desktop) {
        max-width: 1260px;
        width: 100%;
      }
    }
  }
}
