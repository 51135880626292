.discount-banner {
  display: flex;
  width: 100%;
  height: 2rem;
  align-items: center;
  border: 1px solid #32363B;
  background: black;

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__logo {
    margin: 0 0.75rem;
    width: auto;
    height: 0.75rem;
  }

  &__value {
    display: flex;
    padding: 0 0.25rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #C5310A;
    background-color: #F5CF67;
    font-weight: 900;
    font-size: 1.25rem;
  }

  @media (min-width: $desktop) {
    &__logo {
      width: 8.45rem;
      height: auto;
    }

    &__value {
      padding: 0 1.5rem;
    }
  }
}

.tag-container {
  max-width: 10.75rem;
  margin-right: 0;
  margin-left: auto;
  border-radius: 0.375rem;
}

.right-border-radius {
  border-radius: 0 0.375rem 0.375rem 0;
}

.bottom-right-border-radius {
  border-radius: 0 0 0.375rem 0;
}

.tag__logo {
  margin: 0 0.75rem;
  width: 6.375rem;
}

.tag__value {
  font-size: 0.875rem;
  padding: 0 0.25rem;
}

.creator-tag-container {
  max-width: max-content;
  border-radius: 0 0 0.375rem 0.375rem;
  background-color: #191C1F;
}

.creator-tag__logo {
  margin: 0 0.75rem;
  width: 4rem;

  @media (min-width: $desktop) {
    width: 5.53rem;
  }
}
