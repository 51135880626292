.page-container {
  &--container {
    width: 100%;
    margin: 0 auto;

    @media (min-width: $desktop-min-2) {
      max-width: $desktop-min-2 !important;
    }
  }
}
