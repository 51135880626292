.author-card {
  width: 10rem;
  position: relative;

  @media (min-width: $desktop) {
    width: 13.5rem;
  }

  &__background {
    height: 10rem;
    border-radius: 0.5rem;
    background: linear-gradient(
        180deg,
        rgba(26, 17, 17, 0.15) 46.02%,
        rgba(0, 0, 0, 1) 75.09%,
        rgba(0, 0, 0, 1) 100%
      ),
      rgba(211, 211, 211, 0.64) 50% / cover no-repeat;

    & > span {
      opacity: 0.75 !important;
    }

    .image-avatar {
      border-radius: 0.5rem;
    }

    @media (min-width: $desktop) {
      height: 13.5rem;
    }
  }

  &__data {
    position: absolute;
    width: 10rem;
    top: 55%;
    z-index: 100;
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media (min-width: $desktop) {
      width: 13.5rem;
      top: 63%;
    }

    &--name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      width: 8.5rem;
      color: white;
      font-weight: 700;
    }

    span {
      text-shadow: black 1px 1px;
    }
  }
}

.top-border-radius {
  border-radius: 0.375rem 0.375rem 0 0;
}
