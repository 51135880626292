

#hm-mktplace-footer {
  position: absolute;
  z-index: 20;
  width: 100%;
  z-index: 11;

  @media (max-width: ($desktop - 1)) {
    padding-bottom: 50px;
  }

  @media (max-width: $desktop-min-2) {
    padding: 0 16px;
  }
}

.hm-mktplace-footer {
  .languageTxt-title {
    color: var(--gray-700) !important;
  }

  @media (max-width: $tablet) {
    margin-top: 2rem !important;
  }
}
