.banner-central {
  width: 100%;
  max-width: 1366px;
  height: 288px;
  margin-top: 100px;

  &__image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__campaign {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    background-size: cover !important;

    .product-image-wrapper {
      height: 288px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $tablet) {
        height: 178px;
      }
    }

    .product-image {
      width: auto!important;

      @media (max-width: $tablet) {
        height: 100%;
        object-position: 50% 50%;
        object-fit: contain;
      }
    }

    @media (max-width: $desktop) { 
      flex-direction: column;
      justify-content: flex-start;
      height: 320px;

      .product-image-wrapper {
        height: auto;
      }
      
      .banner-central{
        &__cta {
          justify-content: center;
          &-wrapper {
            width: auto;

            .button-ctr {
              margin: 0 auto;
              margin-top: 14px;
            }
          }
        }
      }
    }
  }

  &__image {
    flex: 0 0 45%;
    order: 1;

    @media (max-width: $mobile-max) {
      flex: 0 0 30%;
      padding-left: 8px;
    }
  }

  &__cta {
    position: relative;
    display: flex;
    order: 2;

    &-wrapper {
      position: absolute;
      width: 100%;
      top: 40px;
      left: 20px;
      padding: 0 32px;
      
      .highlight {
        font-weight: bold;
        color: #52c1c3;
      }

      .button-ctr {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        color: $white;
        border-radius: 6px;
        padding: 12px 16px;
        max-width: 250px;
        border-radius: 24px;
        transition: all $timing-3 $ease;
        cursor: pointer;
        box-shadow: 0 2px 5px 0 #417f80;

        span {
          text-decoration: none;
          font-size: 14px;
          font-weight: 900;
          color: $white;
        }

        svg {
          width: 14px;
          height: 14px;
        }

        &:hover {
          transition: all $timing-8 $ease;
          background-image: linear-gradient(to left, #fd7750, #fc9f40 180%) !important;
          span {
            color: white
          }
        }

        &.external {
          &:hover {
            transition: all $timing-8 $ease;
            color: white;
            background-color: #51D88C;
            text-decoration: underline;
            background-image: none !important;
            span {
              color: white
            }
          }
        }
      }
    }

    @media (min-width: $mobile) {
      &-wrapper {
        top: 0;
        left: inherit;

        h2 {
          font-size: 14px;
          line-height: 18px;
        }

        h4 {
          font-size: 14px;
          font-weight: normal;
          line-height: 32px;
        }

        h6 {
          font-size: 12px;
          margin-bottom: 16px;
          @media (max-width: $mobile-l) {
            margin-bottom: 4px;
          }
        }
      }

      @media (max-width: $mobile) { 
        h4 {
          font-size: 12px;
          line-height: 24px;
        }
      }

      .button-ctr {
        margin-top: 8px;
        padding: 8px;
        span {
          font-size: 12px;
        }
      }
    }

    @media (min-width: $tablet-max) {
      flex: 0 0 40%;

      &-wrapper {
        top: 16px;
        left: inherit;
        padding: 0 100px 0 0px;

        h2 {
          font-size: 18px;
          line-height: 24px;
        }

        h4 {
          font-size: 21px;
          font-weight: normal;
        }

        h6 {
          font-size: 12px;
          @media (max-width: $mobile-l) {
            margin-bottom: 8px;
          }
        }

        span {
          font-size: 12px;
        }
      }
    }

    @media (min-width: $desktop-m) {
      flex: 0 0 45%;
      &-wrapper {
        top: 20px;
        h2 {
          margin: 0;
          font-size: 40px;
          line-height: 1;
          font-weight: 900;
          color: $white;
        }

        h4 {
          margin: 0;
          font-weight: normal;
          font-size: 21px;
          color: #2a8982
        }

        h6 {
          color: #024974;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 14px;
          letter-spacing: 0;
          margin-bottom: 24px;
        }

        .button-ctr {
          padding: 12px 16px;
          margin: 0 auto;
          margin-top: 24px;

          span {
            font-size: 14px;
          }
        }
      }
    }

  }

  @media (max-width: $tablet) {
    height: auto;
  }
}

