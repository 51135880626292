.hotmarter-image {
  display: block;
}

.producer-image-wrapper {
  position: relative;
  display: flex;

  .producer-image {
    display: block;
    width: 100%;
  }
}
