

.button-iconed {
  $self: &;
  display: flex;
  justify-content: center;
  border: 1px solid $season-button-iconed-border;
  border-radius: 6px;
  background: transparent;
  align-items: center;
  padding: 16px;
  width: 100%;
  transition: all $ease $timing-5;
  cursor: pointer;

  &__label {
    color: $white;
    font-weight: 500;
    font-size: 16px;
  }

  &__icon-marker {
    display: flex;
    margin-left: 8px;
    align-items: center;
  }

  &__marker {
    color: $season-button-iconed-marker-color;
    margin-right: 8px;
    transition: all $ease $timing-5;
    font-weight: bold;
  }

  &__icon {
    color: $white;
    width: 16px;
    svg{
      position: relative;
      vertical-align: middle;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: $season-button-iconed-hover-background;

      #{$self}__marker {
        color: $season-button-iconed-hover-marker-color;
        margin-right: 8px;
      }
    }
  }
}
