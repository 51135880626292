

.banner-carousel {
  width: 100%;
  max-width: 1366px;
  height: 288px;
  cursor: pointer;

  &__wrapper {
    max-width: 1260px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    @media (max-width: $desktop) { 
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__campaign {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: 288px;
    background-size: cover !important;

    .product-image-wrapper {
      height: 288px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $tablet) {
        height: auto;
      }
    }
    .product-image {
      width: auto!important;

      @media (max-width: $tablet) {
        height: 100%;
        width: 100% !important;
        object-position: 50% 50%;
        object-fit: contain;
      }
    }

    @media (max-width: $tablet) {
      height: 178px;
    }
  }

  &__image {
    flex: 0 0 50%;

    @media (max-width: $mobile-max) {
      padding: 0 16px 0 0;
      flex: 0 0 40%;
    }
  }

  &__cta {
    position: relative;
    flex: 0 0 100%;
    display: flex;

    &-wrapper {
      position: absolute;
      width: 100%;
      top: 40px;
      left: 20px;
      padding: 0 36px 0 0;

      .button-ctr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        color: $white;
        border-radius: 6px;
        text-decoration: none;
        padding: 8px 16px;
        box-shadow: 0 2px 5px 0 #417f80;
        max-width: 250px;
        border-radius: 24px;
        transition: all $timing-8 $ease;
        cursor: pointer;

        a {
          text-decoration: none;
          font-weight: bold;
        }
        span {
          text-decoration: none;
          font-size: 14px;
          font-weight: 900;
          color: $white;
        }

        svg {
          width: 14px;
          height: 14px;
        }

        &:hover {
          transition: all $timing-8 $ease;
          color: white;
          background-image: linear-gradient(to left, #fd7750, #fc9f40 200%) !important;
          span {
            color: white
          }
        }

        &.external {
          &:hover {
            transition: all $timing-8 $ease;
            color: white;
            background-color: #51D88C;
            text-decoration: underline;
            background-image: none !important;
            span {
              color: white
            }
          }
        }
      }
    }

    @media (min-width: $desktop-m) {
      &-wrapper {
        top: 30px;
        left: 24px;

        h2 {
          margin: 0;
          font-size: 40px;
          line-height: 1;
          font-weight: 900;
          color: #f13837;
        }

        h4 {
          margin: 0;
          line-height: 1;
          font-weight: 900;
          font-size: 36px;
          color: #2a8982
        }

        h6 {
          color: #024974;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0;
          margin-bottom: 24px;
        }

        .button-ctr {
          margin-top: 24px;
          padding: 12px 16px;

          span {
            font-size: 14px;
          }
        }
      }
    }

    @media (min-width: $mobile) {

      &-wrapper {
        top: 14px;
        left: inherit;
        padding: 0 8px 0 16px;

        h2 {
          font-size: 14px;
          line-height: 18px;
        }

        h4 {
          font-size: 14px;
          line-height: 20px;
        }

        h6 {
          font-size: 9px;
          margin-bottom: 16px;
          @media (max-width: $mobile-l) {
            margin-bottom: 5px;
          }
        }

        .button-ctr {
          margin-top: 8px;
          padding: 8px;
          span {
            font-size: 12px;
          }
        }
      }
    }

    @media (min-width: $mobile-m) {
      flex: 0 0 100%;

      &-wrapper {
        top: 16px;
        left: inherit;
        padding: 0 8px 0 16px;

        .button-ctr {
          max-width: 148px;
        }
      }

      h2 {
        font-size: 18px;
        line-height: 24px;
      }

      h4 {
        font-size: 16px;
        line-height: 20px;
      }

      h6 {
        font-size: 10px;
        margin-bottom: 8px;
      }

      span {
        font-size: 12px;
      }
    }

    @media (min-width: $tablet) {
      &-wrapper {
        top: 16px;
        padding: 0 16px;

        h6 {
          margin-bottom: 16px;
        }

        h4 {
          line-height: 1;
          font-size: 26px;
        }
      }
    }
  }
  .carousel {
    width: 100%;
    @media (min-width: $mobile) {
      &__item {
        padding-left: 0;
      }

      &__page {
        &:first-child {
          .banner-carousel__cta {
            @media(max-width: $desktop-min-2) {
              &:first-child {
                left: 20px;
              }
            }
          }
        }
      }
    }
  }

  .slick-arrow-buttons {
    &.left, &.right {
      position: absolute;
      z-index: 1;
    }
  }  

  @media (max-width: $tablet) {
    height: 178px;
    margin-top: 24px;
    .slick-slide {
      padding: 0 !important;
    }

    .slick-arrow-buttons {
      display: none;
    }
    
    .slick-dots {
      justify-content: center;
      margin-top: 8px;
    }
  }

  @media (min-width: $tablet) {
    margin-top: 48px;

    .slick-slide {
      padding: 0 !important;
    }
  }
}

.search-container .search__section .banner-carousel {
  .slick-list {
    margin-left: 0;

    @media (max-width: $desktop-m) {
      margin-left: 0;
    }
  }
}

.banner-slick {
  margin-bottom: 40px;
}


