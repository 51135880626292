.results-filter-placeholder {
  $self: &;

  &__black-november {
    padding: 0 1rem 0 0;

    #{$self}__container {
      border: 1px solid var(--gray-200);
      border-radius: 6px;
      padding: 1rem 0.75rem;
    }

    #{$self}__title {
      color: var(--black) !important;

      &__type {
        margin-right: 0.5rem;
      }
    }

    .custom-coupon-filter {
      &__link {
        padding: 0.5rem;
        background: var(--brand-primary-light);
        border-radius: 0.25rem;
        text-decoration: auto;
        color: var(--white);
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;

        svg {
          width: 0.75rem;
          height: 0.75rem;
          margin-left: 0.25rem;
        }
      }

      .selectable-list-counter {
        ul {
          display: flex;
          flex-direction: column;

          li:first-child {
            order: 2;
          }

          li:nth-child(2) {
            order: 3;
          }

          li:nth-child(3) {
            order: 4;
          }

          li:nth-child(4) {
            order: 5;
          }

          li:nth-child(5) {
            order: 6;
          }

          li:nth-child(6) {
            order: 1;

            .hot-form__label {
              & > span:before {
                content: '' !important;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $mobile-content-max) {
    &__black-november {
      padding: 0 1rem 2rem 0;

      .custom-coupon-filter {
        &__link {
          font-size: 0.75rem;
          padding: 0.25rem 0.5rem;
        }
      }
    }
  }

  @media (min-width: $desktop-m) {
    &__black-november {
      padding: 0 3rem 2rem 0rem;
    }
  }
}
