hot-nav-item {
  border: none !important;

  &:hover {
    box-shadow: none !important;
  }
}

.nav-item--active {
  --component-border-width: 2px;
}
