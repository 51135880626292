
.product-image-wrapper {
  position: relative;

  .product-image {
    display: block;
    width: 100%;
  }

  &.product-alt {
    height: 72px;
    display: flex;

    @media (min-width: $mobile) {
      width: 84px;
      height: 84px;
    }

    @media (min-width: $tablet-min) {
      width: 72px;
    }
  }

  &.card-list {
    display: flex;
  }
}
