.button--show-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0;
  gap: 0.5rem;

  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  border: none;

  &-text {
    font-weight: 800;
    font-size: var(--text-2);
    line-height: var(--line-height-lg);
    color: var(--gray-900);
  }
}
