.grid-product-card {
  &--container {
    width: 160px;
    box-shadow: 0px 8px 8px -3px rgba(0,0,0,0.1);
    transition: box-shadow 0.5s;

    .price {
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--gray-800);
    }

    @media screen and (min-width: $desktop) {
      width: 294px;
      box-shadow: none;

      &:hover {
        box-shadow: 0px 8px 8px -3px rgba(0,0,0,0.1);
      }
    }
  }

  &--creator {
    width: 150px;
  }

  &--header {
    img, .content-loader--container {
      width: 160px;
      height: 160px;

      @media screen and (min-width: $desktop) {
        width: 294px;
        height: 294px;
      }
    }
  }

  &--creator {
    @media screen and (min-width: $desktop) {
      width: 187px;
    }
  }

  &--price {
    &-line-through {
      text-decoration: line-through;
    }
  }

  &--body {
    min-height: 98px;
    padding: 1rem 0.5rem;

    &-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
