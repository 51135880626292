

.search-others {
  &__title {
    color: #343a42;
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .link-iconed {
    margin-top: 16px;
    border: 1px solid #d0d3d6;
    &__label {
      color: #171717;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
    &__icon {
      color: #171717;
      width: 13px;
    }

    &:hover {
      background: #eeeeee;
    }
  }

  .slick {
    $slick: &;
    &-track{
      display: flex;
    }

    &-list {
      margin-left: -16px;
      margin-right: -16px;
    }

    &-slide {
      padding: 0 16px;
    }

    &-arrow-buttons {
      bottom: 18px;
    }

    &-dots {
      height: 40px;
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    &__title {
      font-size: 32px;
      line-height: auto;
      margin-bottom: 32px;
    }
  }
}
