

.no-results-season {
  &__container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    margin: 0px 0px 32px 20px;
    text-align: center;
    &__image {
      width: 60%;
    }
  }
  &__content {
    flex: 1;
    text-align: center;
    color: $white;
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__term {
    display: block;
  }
  &__text {
    color: #a4a4a4;
  }

  @media screen and (min-width: 683px) {
    &__container {
      flex-direction: row;
    }

    &__icon {
      margin: 0px 32px 0px 0px;
      &__image {
        width: 218px;
      }
    }

    &__content {
      text-align: left;
    }

    &__title {
      font-size: 16px
    }
    &__term {
      font-size: 50px;
    }
    &__text {
      font-size: 16px;

    }
  }
}
