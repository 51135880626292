.most-accessed {
  &__container {
    width: 100%;
    padding: 64px 0;
    overflow: hidden;
  }

  &__row {
    display: flex;
    padding-top: 3px;
    margin-bottom: 10px;
    overflow-x: scroll;

    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    will-change: transform;
    transform: translateZ(0);

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .category {
      &__link {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        @media (max-width: $desktop-m) {
          &:first-child {
            margin-left: 16px;
          }
  
          &:last-child {
            margin-right: 16px;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
    padding-bottom: 24px;

    @media (max-width: $mobile-m) {
      padding-bottom: 16px;
    }

    @media (max-width: $desktop-m) {
      padding-left: 16px;
    }
  }

  &__title {
    font: 600 32px var(--font-family);
    line-height: var(--line-height);
    letter-spacing: 0px;

    @media (max-width: 900px) {
      font-size: 20px;
    }
  }

  &__explorer-link {
    text-decoration: none;
    color: var(--brand-primary) !important;

    &.hot-button:hover {
      background-color: #FDECE7;
    }

    svg {
      margin-left: 8px;
      width: 12px;
      height: 12px;
      font: 300 20px var(--font-family);
      letter-spacing: 0%;
      text-align: center;
      vertical-align: middle;
    }
  }
}
