

.selectable-list {
  &__no-bullets {
    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;
    }
  }

  &__item {
    transition: all $timing-5 $ease;
    margin-bottom: 13px;
    cursor: pointer;
    border-radius: 12px;
    border: solid 1px #dfe2e6;
    padding: 13px 40px 13px 16px;
    position: relative;
    font-size: 16px;
    font-weight: 300;

    &--selected {
      border: 1px solid #eeeeee;
      background: #eeeeee;
      font-weight: 600;
    }
    &__icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      svg {
        width: 10px;
      }
      &--minus {
        color: #38a8a7;
      }
      &--plus {
        color: #fd937b;
      }
    }

    @media(hover:hover){
      &:hover{
        border: 1px solid #343A42;
      }
    }

    @media screen and (min-width: $tablet-mid-2) {
      font-size: 14px;
    }
  }

  .show-all-button {
    cursor: pointer;
    margin-top: 24px;
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #171717;

    svg {
      font-size: 10px;
      margin-left: 10px;
      transition: $timing-5 all $ease;
      max-width: 9px;
      color: #171717;
      vertical-align: middle;
    }

    &__open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
