.category-signup {
  background-color: $red-1;
  border-radius: 8px;
  display: none;
  margin: 0.5rem 0 2rem 0;
  padding: 3rem;
  text-align: center;
  transition: all ease-in-out 0.25s;

  @media (min-width: 768px) {
    padding: 1rem 1.5rem;
    text-align: left;
  }

  &:hover {
    box-shadow: 0px 8px 8px -3px rgba(0, 0, 0, 0.1);
  }

  &__link {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
    text-decoration: none;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: $desktop) {
      gap: 3rem;
    }

    &:hover {
      color: white;
    }
  }

  &__content {
    flex-grow: 1;
    text-align: center;

    @media (min-width: 768px) {
      padding: 2rem 0 2rem 2rem;
      text-align: left;
    }
  }

  &__title {
    font-family: 'dharma-gothic-e', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0.5rem;

    @media (min-width: $mobile-l) {
      font-size: 2.25rem;
    }

    @media (min-width: $desktop) {
      font-size: 2.5rem;
    }

    span {
      font-size: 2rem;

      @media (min-width: $mobile-l) {
        font-size: 3rem;
      }
    }
  }

  &__description {
    color: white;
    font-size: 1rem;
    line-height: 1.2;

    &--mobile {
      display: block;
      margin-bottom: 1rem;

      @media (min-width: $desktop) {
        display: none;
      }

      p {
        color: white;
        font-size: 1rem;
        line-height: 1.15;
      }
    }

    &--desktop {
      display: none;
      font-size: 1rem;
      line-height: 1.15;
      margin-bottom: 1rem;

      @media (min-width: $desktop) {
        display: block;
      }
    }

    strong {
      font-weight: 900;
    }
  }

  &__button {
    border: none;
    background-color: #009d43;
    border-radius: 0.25rem;
    color: $white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    padding: 1rem 2rem;
    transition: all 0.25s ease-in-out;

    @media (min-width: $desktop) {
      padding: 1rem 3rem;
    }

    &:hover {
      filter: brightness(0.9);
    }

    svg {
      margin: -0.25rem 0 0 0.5rem;
      height: auto;
      width: 1rem;
    }
  }

  &__image {
    order: -1;

    @media (min-width: 768px) {
      order: initial;
    }

    img {
      height: auto;
      min-width: 200px;
      width: 100%;
    }
  }
}
