.content-tags {
  &--title {
    font-weight: 800;
  }
  &--description {
    font-weight: var(--font-weight);
    font-size: 14px;

    @media screen and (min-width: $desktop) {
      font-size: 20px;
      font-weight: var(--font-weight-light);
    }
  }
  &--tags {
    display: flex;
    padding: 20px 0px;
    overflow: auto;

    * {
      white-space: nowrap;
    }

    span {
      font-weight: 800;
      text-decoration: underline;
    }
  }
}
