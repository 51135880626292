.suggestions-list {
  $self: &;

  &__fire {
    list-style: none;

    a {
      text-decoration: none;
    }

    span {
      border: 1px solid var(--neutral-white, #FFF);
      background: linear-gradient(239deg, #3C2D6F 0%, #06040D 100%);
      gap: 0.5rem;
      color: white;
      padding: 0.375rem 1rem;
      white-space: nowrap;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding-left: 0;
    max-width: 100%;
  }

  // hiding scrollbar visually
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__title {
    font-size: 12px;
    margin-right: 9px;
    font-weight: 400;
  }

  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    color: var(--gray-500);
    margin-right: .5rem;
  }

  &__tag {
    $tag: &;
    margin: 0 2px;
    border-radius: 20px;

    &--disabled {
      &:not(.hot-tag--active) {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    h3.suggestions-list__tag__body {  
      all: unset;
      display: block;
    }

    &__body {
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      padding: 8px 16px;
      font-weight: normal;
      cursor: pointer;
      margin-right: 3px;
      align-items: center;
      a {
        text-decoration: none;
      }

      &__image {
        display: block;
        margin-left: 8px;
      }
    }

    &--black-november {
      #{$tag} {
        &__body {
          background: #191c1f;
        }
      }
    }
  }

  a:hover {
    color: inherit;
  }

  &__suggestion {
    &__link {
      display: inherit;
      text-decoration: none;
      margin-bottom: 6px;
    }
  }

  &--vertical {
    #{$self}__content {
      flex-wrap: wrap;
    }

    #{$self}__title {
      flex: 0 0 100%;
      margin-bottom: 13px;
    }

    #{$self}__suggestion {
      margin-bottom: 13px;
    }
  }

  &--horizontal {
    margin: 0 auto;
    flex-wrap: wrap;
  }

  &--scrollable {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media (max-width: $mobile-l) {
    &__content {
      justify-content: initial;
    }
  }
}
