
.season-about-container {
  max-width: 1256px;
  margin: 0 auto 24px auto;
  padding: 24px;

  .season-about-title {
    color: $season-about-title-color;
    margin-bottom: 20px;
    font-size: 32px;
  }

  .season-about-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .season-about-text {
    p {
      color: $season-about-text-color;
      font-size: 16px;
      font-weight: normal;
      &:nth-child(1) {
        font-weight: bold;
      }
      strong {
        color: $season-about-text-highlight-color;
        font-weight: bold;
      }
    }
  }

  .body-wrapper {
    margin-bottom: 108px;
    max-width: 536px;
  }

  .coupon-wrapper {
    .product-discount-filter {
      &__title {
        color: #adadad;
      }
    }
  }

  .season-about-cta {
    font-size: 14px;
    color: $season-about-cta-color;
    font-weight: bold;

    .icon {
      width: 48px;
      height: 48px;
      position: relative;
      background: $season-about-cta-background;
      svg {
        width: 13px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background: $season-about-cta-background-hover;
      }
    }
    &:hover {
      .icon {
        background: $season-about-cta-background-hover !important;
      }
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    .season-about-section {
      flex-direction: row;
    }
    .coupon-wrapper {
      background: $season-about-coupons-filter-background;
      transform: translateY(-90px);
      .product-discount-filter {
        padding: 52px 24px;
      }
    }
  }

  @media screen and (min-width: $desktop-m) {
    padding: 24px 0px;
    width: 100%;
  }
}
