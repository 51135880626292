.creator {
  &__name {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: 0.48px;

    &--mobile {
      letter-spacing: 0.32px;
    }
  }

  &__container {
    border-radius: 0.75rem;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.18) 63.14%,
        rgba(22, 20, 20, 0.67) 82.99%,
        rgba(0, 0, 0, 0.7) 100%
      ),
      lightgray 0px -13.619px / 100% 150.037% no-repeat;

    & > span {
      opacity: 0.7 !important;
    }
  }

  &__container,
  &__image {
    width: 96px !important;
    height: 96px !important;
    border-radius: 12px;
  }

  &__social {
    border-radius: 100px;
    background: var(--gray-100);
    padding: 4px 5.75px;
    margin-right: 0.5rem;

    &__icon {
      height: 1.25rem;
    }
  }

  &__links {
    width: max-content !important;
  }

  &__icon {
    width: 1.25rem;
  }

  &__mobile {
    display: block;
  }

  @media (min-width: $desktop) {
    &__mobile {
      display: none !important;
    }

    &__name {
      font-size: 3rem;
      letter-spacing: 0.96px;
    }

    &__container,
    &__image {
      width: 185px !important;
      height: 183px !important;
    }
  }
}

.creator-container {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, 152px);

  .price {
    font-size: 1.25rem !important;
    line-height: 120%;
  }

  @media (min-width: $desktop) {
    grid-template-columns: repeat(auto-fill, 192px);
  }
}

.creator-share {
  button {
    background: transparent;
    border: none;
    padding: 0;
  }

  @media (min-width: $desktop) {
    width: 80%;
  }
}

.breadcrumbs__section span,
.creator__name,
.creator__products {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: $desktop) {
    -webkit-line-clamp: 1;
  }
}
