.category-card-accordion {
  $self: &;
  border: 1px solid $gray-6;
  border-radius: 6px;
  background-color: $white;
  overflow: unset;

  &-image {
    cursor: pointer;
    background: linear-gradient( rgba(30, 79, 199 , 0.90), rgba(30, 79, 199, 0.55) ), url(/s/static/images/categories.png) top;
    height: 80px;
    width: 100%;
    overflow: hidden;
    position: relative;

    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      color: $white;
      height: inherit;
      width: 100%;
      height: 80px;

      div {
        line-height: 30px;
      }

      #{$self}-title__icon {
        color: var(--white);
      }
    }

    &__title {
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 108%;
      width: 50%;
    }

    &__link {
      text-decoration: auto;
    }

    @media (max-width: $tablet-max) {
      &__text {
      div {
          font-size: 1rem;
        }
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  &__content { 
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
  }
  
  .popover-component {
    top: 10%;
    left: 85%;
    transform: translateY(-20%);
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    text-decoration: none;

    span {
      width: 100%;
    }

    &__icon {
      line-height: 1.88;
      font-size: 12px;
      color: #219392;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      svg {
        height: 14px;
        width: 14px;
        margin-left: 8px;
        position: relative;
      }

      &:before {
        content: '';
        position: absolute;
        right: -12px;
        width: 40px;
        height: 40px;
        opacity: 0.2;
        border-radius: 50%;
        background-color: #ffffff;
        transition: all $timing-5 $ease;
      }

      @media (max-width: $tablet-max) {
        font-weight: bold;
        font-size: 12px;
        line-height: 23px;
        margin-left: 2rem;
        

        &:before {
          right: -13px;
        }
      }

      &:hover {
        &:before {
          transform: scale(1.2);
          opacity: 0.2;
        }
      }
    }

    @media (max-width: $tablet-max) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      padding-bottom: 8px;
    }
  }

  .product-card__title {
    max-height: 3.275rem;
  }

  &-products {
  div {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--gray-800);
    }
  }

  &-product {
    display: flex;
    text-decoration: none;
    align-items: center;
    padding-top: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    position: relative;

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 16px;
      white-space: nowrap;
      max-width: 291px;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    .product-image-wrapper {
      width: 64px;
      height: 64px;
      border-radius: 6px;
      overflow: hidden;
      margin-right: .5rem;
    }

    &:hover {
      color: var(--blue);
    }

    @media (max-width: $tablet-max) {
      font-size: 12px;
      padding: 15px 0px;
      justify-content: space-between;
    }
  }

  @media (min-width: $desktop-m) {
    &-product {
      .product-image-wrapper {
        width: 84px;
        height: 84px;
      }
    }
  }
}
