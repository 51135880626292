.season-about-header {
  background: $season-header-background-image, $season-header-background-color;
  padding-top: 128px;
  &__container {
    max-width: 1256px;
    margin: 0 auto;
    padding: 0 25px;
    h1 {
      color: $white;
      font-weight: normal;
      font-size: 32px;
      font-weight: bold;
      strong {
        font-weight: bold;
      }
    }
  }
  @media (min-width: $tablet-max) {
    padding-top: 194px;
    &__container {
      h1 {
        font-size: 48px;
      }
    }
  }

  @media (min-width: $desktop-m) {
    &__container {
      padding: 0;
    }
  }
}
