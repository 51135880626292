

.user-empty-state {
  &__container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    margin: 0px 0px 32px 20px;
    text-align: center;
    &__image {
      width: 60%;
    }
  }
  &__content {
    flex: 1;
    text-align: left;
  }

  &__title {
    color: #343a42;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin-bottom: 24px;
    font-size: 16px;
  }

  &__text {
    color: #a4a4a4;
  }
}
