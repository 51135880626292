.category {
  &__link {
    display: flex;
    align-items: flex-end;
    position: relative;
    overflow: hidden;

    height: 80px;
    flex: 0 0 172px;

    padding: var(--spacer-3);
    border-radius: var(--spacer-4);
    color: var(--white);
    text-decoration: none;

    will-change: transform;
    transform: translateZ(0);
    transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        to top,
        #121212 2.5%,
        rgba(21, 21, 21, 0) 89.09%
      );
      z-index: 1;
    }

    &:hover {
      cursor: pointer;
      transform: translateY(-3px);
    }

    @media (max-width: $mobile-m) {
      height: 64px;
      flex-basis: 134px;
    }
  }

  &__image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &__name {
    position: relative;
    z-index: 2;
    font-weight: 800;
    line-height: var(--line-height);
    color: var(--white);

    @media (max-width: $mobile-m) {
      font-size: 12px;
    }
  }
}
