.avatar {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid $grey-4;
  .hotmarter-image {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 110%;
  }
}
