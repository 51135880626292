

.category-header {
  $self: &;
  background: var(--blue);
  background-image: url(/s/static/images/category_banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: .5rem 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;

  &--hide-details {
    #{$self}__informations {
      display: flex;
      align-items: center;
    }
  }

  &__container {
    text-align: center;
  }

  &__informations {
    color: $white;
    padding: 12px 12px 12px 0px;
    flex: 0 0 60%;
    position: relative;
    z-index: 2;

    &__title {
      font-style: normal;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }

    &__details {
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      margin-left: 0.5rem;
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    &__container {
      position: relative;
    }

    &__informations {
      padding: 14px 0px;
      flex-basis: 75%;
      #{$self}__informations__title {
        font-weight: 300;
        font-size: 28px;
        line-height: 120%;
      }

      #{$self}__informations__details {
        font-size: 1rem;
        margin-left: 0;
      }
    }
  }
}
