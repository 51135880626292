.creator-modal {
  &__body {
    overflow: auto;
    max-height: 700px;

    h2 {
      text-align: start;
    }

    @media screen and (max-width: $mobile-l) {
      max-height: 720px;
    }

    @media screen and (max-width: $mobile-m) {
      max-height: 550px;
    }

    .product-card-alt {
      width: 100%;
    }

    .load-more button,
    .product-card-alt__description {
      color: inherit !important;
    }

    .product-card-alt--black-november .product-card-alt__description {
      color: var(--white) !important;
    }

    .product-card-alt__content-top {
      flex-basis: 65%;
    }

    .load-more { 
      width: 100%;
      margin-top: 0;
      margin-bottom: 2rem;

      h3 {
        display: none;
      }
    }
  }
}
