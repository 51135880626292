.hot-tooltip-wrapper {
  position: relative;
}

.hot-tooltip-wrapper, .hot-tooltip-content-wrapper {
  display: inline;
}

.hot-tooltip-wrapper [class*="hot-tooltip--top"] {
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
}

.hot-tooltip-wrapper .hot-tooltip--top > div:last-child,
.hot-tooltip-wrapper .hot-tooltip--bottom > div:last-child {
  left: 50%;
  margin-left: -.4rem;
  width: 0;
}

.hot-tooltip-wrapper .hot-tooltip--right {
  right: 0;
  transform: translateX(100%) translateY(-50%);
  top: 50%;
  white-space: nowrap;
}

.hot-tooltip-wrapper .hot-tooltip--right > div:last-child,
.hot-tooltip-wrapper .hot-tooltip--left > div:last-child {
  top: 50%;
  margin-top: -.4rem;
}

.hot-tooltip-wrapper .hot-tooltip--left {
  left: 0;
  transform: translateX(-100%) translateY(-50%);
  top: 50%;
}

.hot-tooltip-wrapper .hot-tooltip--bottom {
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(100%);
}

.hot-tooltip {
  min-width: 160px;
}
