

.product-discount-filter {
  text-align: center;
  border-radius: 6px;
  &__title {
    color: $white;
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }

  .filter-coupon__container {
    display: flex;
    justify-content: space-between;
    max-width: 797px;
    margin: 0 auto;
  }

  &--alignment-vertical {
    width: 300px;
    .filter-coupon__container {
      flex-direction: column;
      .filter-coupon {
        margin-bottom: 8px;
      }
    }
  }
}
