.body-regular-black-november {
  .hm-mktplace-header-bar {
    top: 63px;
  }

  .hm-mktplace-header-nav {
    &.logged {
      top: 83px;
      z-index: 10000;
    }

    &.image-header{
      top: -13px;
    }
  }
  
  .search-container {
    padding-top: 224px;
  }

  .results-container {
    padding-top: 84px;
  }

  @media(min-width: 600px){
    .hm-mktplace-header-bar {
      top: 66px;
    }

    .hm-mktplace-header-nav.logged {
      top: 85px;
    }

    .results-container {
      padding-top: 144px;
    }
    
  }

  @media(min-width: 700px){
    .hm-mktplace-header-bar {
      top: 108px;
    }

    .hm-mktplace-header-nav.logged {
      top: 130px;
    }
  }

  @media(min-width: 1025px){
    .hm-mktplace-header-bar {
      top: 108px;
    }

    .hm-mktplace-header-nav.logged {
      top: 150px;

      &.image-header{
        top: -13px;
      }
    }
  }
}
