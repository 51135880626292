

.percentage-bar {
  height: 6px;
  width: 100%;
  background: var(--gray-200);
  border-radius: 40px;
  overflow: hidden;
  .percentage-bar-progress {
    background: var(--gray-400);
    display: block;
    height: 100%;
  }
}
