.sparkle-community-card {
  text-align: left;
  margin-bottom: 16px;

  &__image {
    border-radius: 6px;
    width: 100%;
  }
  &__title {
    margin-top: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  &__type {
    color: #6d7278;
    font-size: 14px;
    line-height: 24px;
  }
  &__cta {
    margin-top: 8px;
    border: 1px solid #5981e3;
    color: #355cc0;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    
    font-size: 12px;
    line-height: 150%;
    transition: 0.5s all ease;

    &:hover {
      background: #5981e3;
      color: #ffffff;
    }
  }

  @media screen and (min-width: 767px) {
    &__cta {
      font-size: 16px;
      padding: 8px 16px;
    }
  }
}
