.buy-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 318px;
  height: 42px;
  margin-top: 1.4rem;
  background: #009D43;
  border: 1px solid #009D43;
  color: var(--white);
  border-radius: 0.25rem;
  font-weight: 400;
  gap: 8px;
  cursor: pointer;

  svg {
    display: none;
  }

  &:hover {
    background-color: var(--green-dark);
    border-color: #006e2f;
    border-color: var(--green-dark);
  }

  @media (min-width: $tablet-mid-1) { 
    font-size: 12px;

    svg {
      display: initial;
      width: 16px;
      margin-left: 8px;
    }
  }
}
