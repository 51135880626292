$ease: cubic-bezier(.4, 0, .14, 1);
$bounce: cubic-bezier(.39, 1.76, .55, .67);

$timing: 800ms;
$timing5: 500ms;
$timing3: 300ms;

// FONTS -----------------------------------------------------------------

$font: 'Nunito Sans', sans-serif;
$fontA: 'Font Awesome 5 Pro';
$fontB: 'Font Awesome 5 Brands';
$fontC: 'Poppins';

// CONTENT & SIDEBAR -----------------------------------------------------------------

$coluna: 580px;

// GRID ------

$unidade: 90px;
$gutter: 16px;

$gapTop: 150px;
$gapTopResize: 50px;

$borderRadius:6px;

@function tamanho($number) {
  $temp: $number - 1;
  $value: $unidade * $number + $gutter * $temp;
  @return $value;
}

/**
* Media queries breakpoint
*/
$mobile: 320px; // mobile
$mobile-m: 375px; // mobile medium
$mobile-l: 414px; // mobile large
$tablet: 768px; // tablet
$desktop: 990px; // desktop
$desktop-m: 1280px; // desktop medium
$desktop-l: 1440px; // desktop large

/**
* Scaffolding
* Settings for some of the most global styles.
*/
$base-ratio: 1.618; // Golden Ratio
$base-spacing: 16px;
$base-border-radius: 4px;
$base-font-family: $font;
$base-font-weight: 400;
$base-font-size: 16px;
$computed-spacing: $base-font-size * $base-ratio;
$line-height-computed: floor($computed-spacing); // Computed line-height for use with margin, padding, etc

// CUSTOM

$borderRadius:4px;
$borderRadiusButton:$borderRadius/2;
$border: 2px;

$boxShadow: 0 2px 2px 0 rgba(72, 78, 86, 0.7);
$boxShadowSoft: 0 2px 2px 0 rgba(72, 78, 86, 0.7);
$boxShadowCarrousel: 0 2px 2px 0 rgba(72, 78, 86, 0.7);

// COLORS -----------------------------------------------------------------

$white: #ffffff;
$black: #000;
$red: #ef4e23;
$hm: #053d4e;
$title-card: #286678;
$main-title: #343a42;
$main-text: #70767e;
$light: #51c1c3;
$gray: #eee;
$gray-2: #484e56;
$gray-3: #f0f3f7;
$gray-4: #6b7480;
$gray-5: #cecece;
$gray-6: #dfe2e6;
$gray-medium: #9B9B9B;
$blue-1: #1c76d0;

$gray-border: #d0d3d6;
$gray-light: #D1D1D1;
$blue:#3498db;
$red-dark: #D0021B;

$bgContent: #FFFFFF;
$text:#333;
$textLight:#848A91;

$color1: #155364;
$color2: #3498DB;
$color3: #7E65D0;
$color4: #F04E23;
$color5: #BC9805;
$color6: #2ECC71;
$color7: #51C1C3;
$color8: #848A91;
$color9: #58ACE3;
$color10: #A18CE3;
$color11: #FF7364;
$color12: #F9D543;
$color13: #51D88C;
$color14: #7CDBDB;

$white: #fff;
$black: #000;

$color-primary: #f04e23;
$color-secundary: #053d4e;
$color-tertiary: #51c1c3;

$highlight: var(--hotmart-primary);


$text-color: $black;

$color-collection: ($color-primary $color-secundary $color-tertiary var(--gray-100) var(--gray-200) var(--gray-300) var(--gray-400) var(--gray-500) var(--gray-600) var(--gray-700) var(--gray-800) var(--gray-900)
var(--hotmart-tertiary-lightest) var(--hotmart-tertiary-lighter) var(--hotmart-tertiary-light) var(--hotmart-tertiary) var(--hotmart-tertiary-dark) var(--hotmart-tertiary-darker));

$color-count: length($color-collection);


// $colorShadow: var(--color-theme);

$color-collection: ($color1 $color2 $color3 $color4 $color5 $color6 $color7 $color8 $color9 $color10 $color11 $color12 $color13 $color14);
$color-count: length($color-collection);

@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}
