

.select {
  $self: &;
  position: relative;
  border-radius: 6px;
  border: solid 1px $grey-8;
  background-color: $white;

  &--disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &--placeholder{
    #{$self}__display{
      &__label {
        opacity: 0.5;
      }
    }
  }

  &__display {
    padding: 3px;
    min-height: 30px;
    font-size: 16px;
    color: $grey-9;
    padding: 7px 41px 7px 16px;
    height: 100%;
    display: flex;
    align-items: center;

    &__label {
      width: 100%;
      display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    }

    &__arrow {
      display: block;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid $grey-9;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(-50%, -50%);
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;

    border-radius: 3px;
    border: solid 1px $grey-7;
    background-color: $white;
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.1)
  }

  &__option {
    cursor: default;
    padding: 12px;
    background: $white;
    transition: $timing-3 all $ease;
    &:hover {
      background: var(--blue);
      color: $white;
    }
  }
}
