.best-courses__container {
  background: var(--gray-100);
  width: 100%;
  padding: var(--spacer-10)  var(--spacer-5);
  text-align: center;

  @media (max-width: $desktop) {
    margin-top: -20px;
    padding-bottom: var(--spacer-8) ;
  }

  .best-courses__courses{
    &-number {
      color: var(--brand-primary);
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: var(--spacer-2);
      display: block;
      
      &--value {
        border-radius: 20px;
        background: #FDECE7;
        padding: 2px 10px;
      }
    }

    &-title {
      font-size: 40px;
      font-weight: 600;
      max-width: 510px;
      margin: 0 auto;
      
      @media (max-width: $desktop) {
        font-size: 24px;
      }
    }
  }
}