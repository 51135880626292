.product-card {
  &__tile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    border-radius: 6px;
    border: solid 1px #f0f3f7;
    border-top: 0px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    max-height: 32px;
    margin-top: -1px;
    height: 100%;
    color: $white;
    margin-bottom: 16px;

    &-icon {
      margin-left: 8px;
      font-size: 14px;
      height: 1em;
      display: none;
    }

    &-text {
      margin-left: 8px;
      font-size: 12px;
    }

    &-season {
      border: 1px solid #000000;
    }
  }

  &__main {
    width: 45%;
    height: inherit;
    object-fit: cover;
  }

  &__image {
    width: 3.5rem;
    height: auto;
    margin-left: .2rem;
  }

  @media (min-width: $tablet) {
    &__tile {
      margin-bottom: 0;
    }

    &-icon {
      display: block;
    }

    &__image {
      width: 4.6rem;
      height: 0.7rem;
      margin-left: .5rem;
    }

    &__main {
      width: 40%;
    }
  }
}
