@import '../../global/styles/variables';
@import '../../global/styles/keyframes';
@import '../../global/styles/_breakpoints.scss';

.async-hide { opacity: 0 !important }

.body-container {
  display: flex;
  min-height: 100%;

  &.has-sidebar {
    flex-direction: row;
  }

  &.has-topbar {
    flex-direction: column;
  }

  .body {
    flex: 1;
    max-width: 100%;
    overflow-x: hidden;

    &__content {
      padding-top: 3.5rem;

      @media (min-width: $tablet) {
        padding-top: 4.375rem;
      }
    }
  }

  &.body-black-november {
    .body {
      flex: 1;
      max-width: 100%;
      overflow-x: hidden;
  
      &__content {
        padding-top: 2rem;
  
        @media (min-width: $tablet) {
          padding-top: 4.375rem;
        }
      }
    }
  }

  .menubar-placeholder- {
    &topbar,
    &sidebar {
      flex: 0 0 auto;
    }

    &topbar {
      height: 60px;
    }
    &sidebar {
      width: 69px;
    }
  }
}

.mmOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--gray-500);
  pointer-events: none;
  z-index: 11;
  opacity: 0;
  cursor: pointer;
  transition: opacity $ease $timing5, background-color $ease $timing5;
  &.active {
    opacity: 0.5;
    pointer-events: all;
    height: 100%;
  }
  &.easy {
    background-color: var(--gray-400) !important;
  }
  &:hover {
    background-color: var(--hotmart-tertiary);
  }
}
