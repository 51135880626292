

.page-container {
  $self: &;
  max-width: 100%;

  &__content {
    margin: 0 auto;
    width: auto;
    padding: 0px 16px;
  }

  &--no-pading {
    #{$self}__content {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (min-width: $desktop-min-2) {
    &__content {
      max-width: $desktop-min-2 !important;
    }
  }

  @media screen and (min-width: $content-min) {
    &__content {
      padding: 0px 16px;
    }
  }

  @media screen and (max-width: $desktop-m) {
    &__content {
      padding: 0;
    }
  }

  @media screen and (min-width: $desktop-min-2) {
    &__content {
      padding: 0px;
    }
  }

  @media screen and (min-width: $desktop) and (max-width: $desktop-m) {
    &__content {
      padding: 0px 16px;
    }
  }
}
