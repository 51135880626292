.popover-component {
	position: absolute;
	z-index: 159;

	&__content {
		position: relative;
		max-width: 17.5rem;
		padding: 1.5rem 1rem;
		background-color: var(--white);
		box-shadow: var(--box-shadow-lg);
		border-radius: .375rem;
		color: var(--gray-800);
		width: 227px;

		&:after {
			position: absolute;
			content: '';
			left: -10px;
			top: calc(50% - 10px);
			width: 0; 
			height: 0; 
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent; 
			border-right:10px solid var(--white); 
		}
	}

	&__title {
		font-size: 1rem;
		font-weight: var(--font-weight-bold);
		line-height: 150%;
	}

	&--left {
		left: auto!important;
		right: 74%;

		.popover-component__content {
			&:after {
				right: -10px;
				left: auto;
				transform: rotate(180deg);
			}
		}
	}

	&--top-right {
		z-index: 1;
		top: auto !important;
		bottom: 4rem!important;
		right: auto !important;
		left: 0 !important;
		box-shadow: 0px 16px 40px -8px rgba(0, 0, 0, 0.26);
		border-radius: 6px;

		.popover-component__content {
			&:after {
				right: auto;
				left: 1rem;
				top: auto;
				bottom: -0.9rem;
				transform: rotate(270deg);
			}
		}
	}

	&--top-left {
		z-index: 1;
		top: auto !important;
		bottom: 4rem!important;
		right: 0 !important;
		left: auto !important;
		box-shadow: 0px 16px 40px -8px rgba(0, 0, 0, 0.26);
		border-radius: 6px;

		.popover-component__content {
			&:after {
				right: 1rem;
				left: auto;
				top: auto;
				bottom: -0.9rem;
				transform: rotate(270deg);
			}
		}
	}

	&__owner {
		display: flex;
		align-items: center;
		font-size: .75rem;
		margin-top: 0;
	}

	&__avatar {
		margin-right: .5rem;
	}

	&__description {
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 15.5rem;
		max-height: 5.625rem;
		margin-top: 1rem;
		font-size: .75rem;
		line-height: 150%;
	}

	&__advantages {
		display: flex;
		justify-content: space-between;
		margin-top: 1.5rem;
	}

	&__advantage {
		display: flex;
		flex-direction: column;
		align-items: center;

		&__icon {
			svg {
				width: auto;
				height: 1.5rem;
				font-size: 1.5rem;
				color: var(--blue);
			}
		}

		&__title {
			font-size: .625rem;
			font-weight: var(--font-weight-bold);
			line-height: 150%;
			text-align: center;
			margin-top: .5rem;
		}
	}

	&__cta {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: .5rem 1rem;
		border-radius: .25rem;
		background-color: var(--green);
		border: none;
		font-size: 1.25rem;
		line-height: 140%;
		color: var(--white);
		margin-top: 1.5rem;
		width: 100%;
		cursor: pointer;

		svg {
			font-size: 1.25rem;
			width: 1.25rem;
			margin-left: .5rem;
		}
	}

	.product-card__rating {
		margin-bottom: 0;
	}
}