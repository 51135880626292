

.filter-select {
  $self: &;
  display: flex;

  &--vertical {
    flex-direction: column;
    #{$self}__label {
      &--top {
        margin-bottom: 10px;
      }

      &--bottom {
        margin-top: 10px;
        order: 2;
      }
    }
  }

  &--horizontal {
    flex-direction: row;
    align-items: center;

    #{$self}__label {
      &--left {
        margin-right: 10px;
      }
      &--right {
        margin-left: 10px;
        order: 2;
      }
    }
  }

  &__label {
    font-size: 12px;
    font-weight: bold;
    color: $grey-14;
  }

  .select {
    width: 220px;
    height: 48px;
  }
}
