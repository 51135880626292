.rating-filter {
  &__star {
    width: .75rem;
    color: var(--gray-300);

    &-active {
      color: var(--yellow);
    }
  }
  
  .rating {
    top: -.0625rem;
    margin-right: .25rem;
  }

  .star-regular {
    color: var(--gray-300) !important;
  }

  .star-half {
    color: var(--yellow) !important;
  }

  .star-left {
    color: var(--yellow);
    top: 56% !important;
  }

  .star-right {
    color: var(--gray-300) !important;
  }

  .star-solid {
    color: var(--yellow);
  }
}
