.banner-season-central {
  margin-top: 2.5rem;

  &__wrapper {
    text-decoration: none;
  }

  &__container {
    display: flex;
    padding: 16px;

    @media (min-width: $tablet) {
      flex-direction: row;
      padding: 60px;
      justify-content: space-between;

      &-setup {
        min-height: 300px;
      }
    }

    &-setup {
      position: absolute;
      width: 100%;

      @media (min-width: 360px) and (max-width: 360px) {
        padding: 10px;
      }

      @media (min-width: $mobile-m) and (max-width: $mobile-m) {
        padding: 15px;
      }
    }
  }

  &__highlight {
    display: flex;
    align-items: center;

    &-title {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 52px;
      line-height: 4rem;
      color: #e9faee;

      &--text {
        font-weight: 500;
      }

      @media (min-width: $mobile) {
        text-align: center;
      }
    }

    &-image {
      @media (min-width: $mobile) {
        width: 100%;
      }

      @media (min-width: $desktop) {
        width: 180px;
        margin-right: 2rem;
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }

    @media (min-width: $mobile) {
      margin-bottom: 24px;
    }

    @media (min-width: $desktop) {
      flex: 0 0 50%;
    }

    @media (max-width: $desktop) {
      display: none;
    }
  }

  &__setup {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 40rem;
    line-height: 140%;

    &-image {
      height: 1.5rem;
      width: max-content;
    }

    &-main {
      display: flex;
    }

    &-logo {
      max-height: 10.375rem;
      max-width: 8rem;
      height: auto;
    }

    @media (max-width: $tablet), (max-width: $tablet-content-max) {
      &-main {
        width: 156px;
        height: 156px;
        align-self: center;
      }
    }
  }

  &__cta {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $tablet) {
      max-width: 11.375rem;
    }

    &-label {
      text-align: center;
      background-color: $white;
      color: $black;
      border-radius: 6px;
      cursor: pointer;
      border: 1px solid transparent;
      text-decoration: none;
      transition: 0.5s all $ease;
      padding: 8px 16px;
      margin-top: 20px;
      white-space: nowrap;

      @media (min-width: $mobile) {
        margin-top: 5px;
      }
    }

    &-setup {
      color: $white;
      font-weight: normal;

      &:hover {
        opacity: 0.8;
      }
    }

    &-default {
      color: #000000 !important;
    }

    &-description {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: $white;
      word-break: break-word;
      margin: 0;
      margin-top: 1rem;
    }

    &-title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      color: $white;
      word-break: break-word;
      margin-bottom: 20px;
    }
  }

  @media (min-width: $tablet) {
    &__container {
      &-setup {
        padding: 50px 40px;
        flex-direction: row;
      }
    }

    &__cta {
      // flex: 0 0 45%;
      flex-direction: column;

      &-description {
        font-size: 20px;
        line-height: 28px;
        margin-top: 0;
      }

      &-setup {
        width: max-content;
        margin-top: 1rem;
        max-width: 40rem;
      }
    }

    &__setup {
      &-image {
        height: 2.188rem;
        width: 200px;
      }

      &-main {
        margin-left: 0;
      }

      &-logo {
        max-width: fit-content;
      }
    }
  }
}

@media (min-width: $tablet) {
  .lancamentos.banner-season-central__container {
    padding: 48px;
  }
  .lancamentos .banner-season-central__highlight {
    flex-direction: column;
  }

  .lancamentos .banner-season-central__highlight-title--text {
    font-weight: bold;
    font-size: 48px;
  }

  .lancamentos .banner-season-central__highlight-image {
    height: 127px;
  }
}

.banner__season {
  width: 1246px;
  height: 288px;
  min-width: 100%;
  min-height: 288px;

  & > span {
    border-radius: 6px;
  }

  @media (max-width: $desktop) {
    width: 100%;
    height: auto;
    min-width: 100vw;
    min-height: 214px;

    & > span {
      border-radius: 0;
    }
  }
}

.um-por-cento.banner-season-central__container {
  flex-direction: column;
  padding: 20px 26px;
}

.um-por-cento .banner-season-central__highlight {
  display: block;
  margin-bottom: 0;
}

.um-por-cento .banner-season-central__highlight-image {
  height: 36px;
  width: auto;
  margin: 0;
}

.um-por-cento .banner-season-central__cta {
  flex-direction: column;
}

.um-por-cento .banner-season-central__cta-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  margin-bottom: 10px;
}

.um-por-cento .banner-season-central__cta-label {
  margin-top: 0;
  width: fit-content;
}

@media (min-width: $desktop) {
  .um-por-cento.banner-season-central__container {
    flex-direction: column;
    padding: 44px 60px;
  }

  .um-por-cento .banner-season-central__highlight-image {
    height: 58px;
    width: auto;
    margin: 0;
  }

  .um-por-cento .banner-season-central__cta {
    flex-direction: column;
  }

  .um-por-cento .banner-season-central__cta-description {
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 20px;
    max-width: 702px;
  }

  .um-por-cento .banner-season-central__cta-label {
    margin-top: 0;
    width: fit-content;
  }
}

.search__banner-carousel {
  .slick-arrow-buttons {
    top: 45%;
  }

  .embla {
    @media (min-width: $mobile-l) and (max-width: $desktop-m) {
      padding: 0 0.5rem;

      &__dots {
        padding-left: 0.5rem;
      }

      &__button--next {
        right: 4px!important;
      }

      &__button--prev {
        left: 3px;
      }
    }
  }

  .slick-dots {
    margin-left: 1rem;

    @media (min-width: $mobile) {
      margin-top: 2rem;
      display: none !important;
    }

    @media (min-width: $tablet) {
      display: flex !important;
    }
  }

  @media screen and (min-width: 297px) {
    .page-container__content {
      padding: 0 !important;
    }
  }

  @media (max-width: 1280px) {
    .slick-slide {
      padding: 0 !important;
    }
  }
}
