.banner {
  background-color: $red-1;
  color: $white;
  display: none;
  padding: 3rem 1.5rem;
  margin-top: 3rem;

  @media (min-width: $tablet) {
    padding: 2.25rem;
  }

  @media (min-width: $desktop) {
    margin-right: 1rem;
    padding: 2.25rem 3rem;
  }

  .container {
    align-items: center;
    display: grid;
    grid-template-areas:
      'image'
      'content';
    grid-template-columns: 1fr;
    gap: 3rem;

    @media (min-width: $mobile-l) {
      gap: 2rem;
    }

    @media (min-width: $tablet) {
      gap: 3rem;
      grid-template-areas: 'content image';
      grid-template-columns: 1fr 1fr;
    }
  }

  &__content {
    grid-area: content;
    text-align: center;

    @media (min-width: $tablet) {
      text-align: left;
    }

    &__title {
      font-size: 2rem;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 1rem;

      @media (min-width: $tablet) {
        font-size: 1.5rem;
      }

      @media (min-width: $desktop-m) {
        font-size: 2.5rem;
      }
    }

    &--mobile {
      display: block;

      @media (min-width: $desktop) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media (min-width: $desktop) {
        display: block;
      }
    }

    p {
      color: $white;
      font-size: 1.25rem;
      line-height: 1.1;
      margin-bottom: 1rem;

      @media (min-width: $tablet) {
        font-size: 1rem;
      }

      @media (min-width: $desktop-m) {
        font-size: 1.5rem;
      }
    }

    &__button {
      border: none;
      background-color: #009d43;
      border-radius: 0.25rem;
      color: $white;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1;
      padding: 1rem 3rem;
      transition: all 0.25s ease-in-out;

      &:hover {
        filter: brightness(0.9);
      }

      svg {
        margin: -0.25rem 0 0 0.5rem;
        height: auto;
        width: 1rem;
      }
    }
  }

  &__images {
    grid-area: image;
    margin: 0 auto;
    position: relative;

    & > span {
      overflow: initial !important;
    }

    &__image {
      width: 100%;

      @media (min-width: $tablet) {
        margin-top: -100px !important;
      }

      @media (min-width: $desktop) {
        margin-top: -85px !important;
      }
    }
  }

  &__caption {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(12px);
    border-radius: 0.25rem;
    font-size: 1rem;
    left: 1rem;
    right: 1rem;
    line-height: 1.3;
    padding: 0.5rem;
    position: absolute;
    top: 8rem;

    @media (min-width: $mobile-m) {
      padding: 1rem;
      right: 0;
      top: 10rem;
    }

    @media (min-width: $mobile-l) {
      bottom: 0;
      right: 2rem;
      top: initial;
    }

    @media (min-width: $tablet) {
      bottom: -2rem;
      right: 0;
    }

    @media (min-width: $desktop) {
      bottom: 1rem;
      font-size: 1rem;
      left: 2rem;
      right: 2rem;
    }

    @media (min-width: $desktop-m) {
      bottom: 3rem;
      left: 4rem;
      right: 4rem;
    }
  }
}
