.hot-tag {
  &__icon {
    padding-left: 0.5rem;
  }

  &--custom-md {
    display: none;
    font-size: 14px;
    border: none;
    letter-spacing: 0.02em;
  }

  @media (min-width: $desktop) {
    &--custom-md {
      display: flex;
      align-items: center;

      svg {
        color: inherit;
      }
    }
  }
}
