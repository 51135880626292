.product-card-more-results {
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    height: 232px;
    overflow: hidden;
    border-radius: .375rem;

    @media (min-width: 522px) and (max-width: 999px) {
      height: 330px;
    }
      
    @media (min-width: 1000px) {
      height: 400px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      height: 100%;

    div {
        font-size: 1.25rem;
        font-weight: var(--font-weight-bold);
        line-height: 120%;
        color: var(--gray-800);
        transition: all $timing-5 $ease;
        word-break: break-word;
        text-align: center;
        margin-top: .75rem;

        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }

      .icon {
        display: grid;
        place-items: center;
        background-color: var(--white);
        transition: all $timing-5 $ease;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          color: var(--brand-primary);
        }

        @media (max-width: 500px) {
          width: 3rem;
          height: 3rem;

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }

    &--new {
      background-image: none !important;
      border: 1px solid var(--gray-200);

      a {
        text-decoration: auto;
        align-items: baseline;
        padding: 0em 1rem 0rem 2.5rem;

        div {
          font-weight: 200;
          font-size: 40px;
          line-height: 120%;
          text-decoration: auto;
          text-align: inherit;
        }
      }

      .icon {
        background-color: var(--blue) !important;

        svg {
          color: var(--white) !important;
        }
      }

      &:hover {
        background-color: var(--blue);

        a div {
          color: var(--white) !important;
        }

        .icon {
          background-color: var(--white) !important;

          svg {
            color: var(--blue) !important;
          }
        }
      }

      @media (max-width: $desktop-min) {
        a {
          padding-left: 1rem;

          div {
            font-weight: 300;
            font-size: 20px;
          }
        }
      }
    }

    &:hover {
      .icon {
        transform: scale(1.1);
      }
    }
}
