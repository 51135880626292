/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.glider-next,
.glider-prev {
  background: var(--white);
  border-radius: 50%;
  border: solid 0.0625rem var(--gray-800);
  padding: 0.625rem;
  position: relative;
  transition: $timing-5 all $ease;
  cursor: pointer;
  opacity: 1;
  width: 2.5rem;
  height: 2.5rem;
  top: 35%;

  svg {
    color: var(--gray-800);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: $timing-5 all $ease;
    width: 0.625rem;
    height: 20px;
  }
}

.glider-prev {
  left: 0rem;

  &.disabled {
    opacity: 1 !important;

    svg {
      color: var(--gray-200) !important;
    }
  }
}

.glider-next {
  right: -1rem;

  &.disabled {
    opacity: 1 !important;

    svg {
      color: var(--gray-200) !important;
    }
  }
}

.slick-dots,
.glider-dots {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start !important;
  list-style: none;
  padding: 0;
  margin-bottom: 0px;
  margin-top: 24px;

  li {
    height: 10px;
    display: flex;
  }

  .glider-dot {
    width: 32px;
    height: 2px;
    border-radius: 1px;
    margin-right: 6px;
    background-color: #d1d3d6;
    cursor: pointer;

    &.active {
      background-color: #484e56;
    }
  }

  @media (max-width: $tablet-mid-2) {
    margin-left: 0.75rem;
  }
}

.glider-contain {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.glider {
  margin: 0 auto;
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  transform: translateZ(0);

  &::-webkit-scrollbar {
    display: none;
  }
}
.glider-track {
  transform: translateZ(0);
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 1;
}
.glider.draggable {
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}
.glider.draggable .glider-slide img {
  user-select: none;
  pointer-events: none;
}
.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.glider-slide {
  user-select: none;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.glider-slide img {
  max-width: 100%;
}
.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}
.glider-prev,
.glider-next {
  user-select: none;
  position: absolute;
  outline: none;
  background: none;
  padding: 0;
  z-index: 2;
  font-size: 40px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  color: #666;
  opacity: 1;
  line-height: 1;
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67),
    color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.glider-prev:hover,
.glider-next:hover,
.glider-prev:focus,
.glider-next:focus {
  color: #a89cc8;
}
.glider-prev {
  bottom: 0;
  right: 5rem;
}
.glider-next {
  right: -23px;
  left: auto;
}
.glider-next.disabled,
.glider-prev.disabled {
  opacity: 0.25;
  color: #666;
  cursor: not-allowed;

  .slick-arrow-buttons {
    cursor: not-allowed;
    pointer-events: none;
    border: 0.0625rem solid var(--gray-200);
  }
}
.glider-slide {
  min-width: 150px;
  padding: 0 8px;
}
.glider-hide {
  opacity: 0;
}
.glider-dot {
  border: 0;
  padding: 0;
  user-select: none;
  outline: none;
  display: block;
  cursor: pointer;
  color: #ccc;
  border-radius: 999px;
  background: #ccc;
  width: 12px;
  height: 12px;
  margin: 7px;
}
.glider-dot:hover,
.glider-dot:focus,
.glider-dot.active {
  background: #a89cc8;
}
@media (max-width: 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
  }
  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    border-radius: 99px;
    background-color: rgba(156, 156, 156, 0.25);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
  }
}

.embla {
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-min) and (max-width: $desktop-m) {
    padding-left: 1rem;
  }

  &__no-carousel {
    padding-left: 0;
  }
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;

  &__wrapper {
    @media (max-width: $mobile-content-max) {
      margin: 0 1rem;
    }
  }
}

.embla__slide {
  position: relative;
  min-width: 100%;
  padding-left: 10px;

  &__size_4 {
    min-width: 24%;
    margin-left: 1rem;

    @media (max-width: $desktop) {
      min-width: 34%;
    }

    @media (max-width: $tablet-min) {
      min-width: 55%;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__size_5_2 {
    min-width: 20%;
    margin-left: 1rem;

    @media (max-width: $desktop) {
      min-width: 55%;
    }
  }
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 190px;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 37%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;

  @media (max-width: $desktop-m) {
    top: 32%
  }
}

.embla__button:disabled {
  cursor: not-allowed;
  opacity: 1;
}

.embla__button--prev {
  left: -20px;
  width: 40px;
  height: 80px;

  @media (max-width: $desktop-m) {
    left: 2px;
  }

  &:disabled .embla-arrow-buttons {
    cursor: not-allowed;
    pointer-events: none;
    border: 0.0625rem solid var(--gray-200);

    svg {
      color: var(--gray-200) !important;
    }
  }
}

.embla__button--next {
  right: -20px;
  width: 40px;
  height: 80px;

  @media (max-width: $desktop-m) {
    right: -10px;
  }

  &:disabled .embla-arrow-buttons {
    cursor: not-allowed;
    pointer-events: none;
    border: 0.0625rem solid var(--gray-200);

    svg {
      color: var(--gray-200) !important;
    }
  }
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  padding-top: 10px;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: #d1d3d6;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  content: '';
}

.embla__dot.is-selected:after {
  background-color: #484e56;
  opacity: 1;
}

.embla-arrow {
  &-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: var(--white);
    border-radius: 50%;
    border: solid 0.0625rem var(--gray-800);
    padding: 0.625rem;
    position: relative;
    transition: $timing-5 all $ease;
    cursor: pointer;
    opacity: 1;
    width: 2.5rem;
    height: 2.5rem;

    &.left,
    &.right {
      position: absolute;
      z-index: 1;
    }

    &.show {
      opacity: 1;
    }

    svg {
      width: 8px;
      height: 15px;
    }

    @media (max-width: $tablet-mid-2) {
      &.left,
      &.right {
        display: none;
      }
    }

    &:hover {
      box-shadow: 0 0 0.625rem 0 rgba(84, 91, 100, 0.8);
      border: solid 0.0625rem #00181e;
      svg {
        color: #00181e;
      }
    }
  }

  svg {
    color: var(--gray-600);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: $timing-5 all $ease;
    width: 0.625rem;
  }
}

.embla__button--next,
.embla__button--prev {
  svg {
    color: var(--gray-600);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: $timing-5 all $ease;
    width: 0.625rem;
    height: 20px;
  }
}

.search__banner-carousel {
  .embla__button--next,
  .embla__button--prev {
    top: 55%;

    @media (max-width: $desktop-m) {
      top: 50%;
    }
  }
}
