

// RATING ----------------------------------------------------------------------------------------------

.rating {
  font-family: $font-2;
  font-weight: 400;
  width: auto;
  font-size: 12px;
  text-align: left;
  position: relative;
  transition: all $ease $timing-3;
  display: inline-block;
  &:after {
    font-weight: 900;
    content: '';
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
    font-size: inherit;
    text-align: left;
  }

  span {
    float: none;
    position: relative;
    display: inline;
    .star-left {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -55%);
      top: 50%;
    }
    .star-right {
      transform: rotateY(180deg);
      color: $gray-6;
    }
  }

  .star-solid-disabled {
    color: $gray-6;
  }
}
