
.product-list {
  .list-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0 (-$list-gutter + 4) 0 0;
    max-width: 100%;
    .card {
      flex: 0 0 auto;
      flex-basis: 100%;
      margin: 0;
      margin-bottom: $list-gutter;
    }
  }

  @media screen and (min-width: $tablet-min) {
    .list-content {
      margin: 0 (-$tablet-list-gutter + 4) 0 0;
      .card {
        flex-basis: 45.6%;
        max-width: 45.6%;
        margin: $tablet-list-gutter;
        margin-bottom: 0%;
        margin-right: 0%;
      }
    }
  }

  @media screen and (min-width: $tablet-mid) {
    .list-content {
      &.no-padding {
        margin: 0 -40px 0 0;
      }
    }
  }

  @media screen and (min-width: $tablet-mid) {
    .list-content {
      .card {
        flex-basis: 30.5%;
        max-width: 30.5%;
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    .list-content {
      .card {
        flex-basis: 22.8%;
        max-width: 22.8%;
      }
    }
  }
}
