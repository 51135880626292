.season-small-banner {
  $self: &;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 2rem;
  position: relative;
  cursor: pointer;
  margin-top: 1.5rem;

  &__container {
    display: flex;
    position: absolute;
    min-width: 100%;
    align-items: center;
    padding-right: 2rem;
  }

  &__link {
    display: contents;
  }

  &__background-image {
    border-radius: 0;
  }

  &__cta {
    &__button {
      background: $white;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: normal;
      line-height: 150%;
      border-radius: 4px;
      display: inline-block;
      white-space: nowrap;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__content {
    &__logo {
      margin: 8px 0 5px 30px;
      margin-bottom: 10px;
      width: 100%;
      position: relative;

      &-setup {
        max-width: fit-content;
      }
    }

    &-setup {
      margin-left: 3rem;
      font-weight: normal;
    }

    &__text {
      color: $white;
      font-size: 18px;
      margin-left: 2rem;

      &-main {
        font-weight: normal;
      }
    }

    #{$self}__cta {
      margin-top: 14px;

      @media (max-width: $tablet) {
        margin-top: 0;
      }
    }
  }

  &__percentage {
    flex-basis: 35%;
    position: relative;
  }

  &__button-courses {
    display: none;
  }

  @media screen and (min-width: $tablet-mid-1) {
    min-height: 135px;

    &__content {
      display: flex;
      align-items: center;

      #{$self}__cta {
        display: none;
      }
    }

    &__percentage {
      width: 225px;
      height: 135px;
    }

    &__background-image {
      border-radius: 6px;
    }

    &__button-courses {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: $desktop-min) {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: flex-end;
      padding-right: 0rem;

      &__text {
        font-size: 0.875rem;
      }
    }

    &__cta {
      text-align: center;
    }

    &__button-courses {
      flex-basis: 25%;
    }
  }

  &.setup-campaign {
    .season-small-banner {
      &__content {
        align-items: flex-start;
        flex-direction: column;

        &__text {
          font-weight: 500;
        }

        &__logo {
          width: 260px;
          height: 35px;
        }
      }
    }

    @media (max-width: $tablet), (max-width: $tablet-content-max) {
      flex-direction: row;
      padding: 15px;
      height: 174px;

      #{$self}__percentage {
        display: initial;
        width: 156px;
        height: 156px;
        max-width: 156px;
      }

      #{$self}__content__logo {
        max-width: 180px !important;
        max-height: 25px !important;
        margin: 8px 0 0.313rem 8px;
      }

      #{$self}__content__text {
        padding: 0.25rem 0.5rem;
      }
    }
  }

  @media (max-width: $desktop) {
    flex-direction: column;
    background-position: right center;

    &__container {
      align-items: flex-start;
      padding-right: 2rem;
    }

    &__content {
      max-width: 200px;

      &__text {
        margin-left: 0;
        padding: 0 0.5rem;
      }

      #{$self}__cta {
        padding: 0 0 0 0.5rem;
        text-align: left;
      }
    }

    &__percentage {
      display: none;
    }

    &__button-courses {
      padding: 1.5rem 0;
    }
  }
}

.season-small-banner.cozinha-na-pratica {
  .season-small-banner__content {
    margin: 24px;
  }
}
