.filter-row {
  $self: &;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  &__label {
    font-size: 12px;
    color: #191c1f;
    text-align: left;
  }

  &-option--large {
    min-height: 80px;
    min-width: 150px;
  }

  &__label {
    &--left {
      font-size: 0.875rem;
      width: 100px;
      font-style: normal;
      margin-left: 0.2rem;
      font-weight: 400;
      color: var(--white);
    }
  }
}

.filter-row-option {
  $self: &;
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  border-radius: 23px;
  background: var(--gray-200);
  height: 42px;
  border: none;
  transition: 0.5s all;
  cursor: pointer;

  &__label {
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    color: var(--black);
  }

  &__image {
    margin: 0 0.5rem;
    display: inline-flex;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  &--selected {
    background: var(--brand-primary-light);
    border: 1px solid var(--brand-primary-light);
    #{$self}__label {
      color: var(--white);
    }

    #{$self}__image {
      width: 27px;
    }

    #{$self}__close {
      height: 12px !important;
      width: 12px !important;
      max-height: inherit !important;
    }
  }

  &__close {
    object-fit: cover;
  }

  &__tag {
    width: 20px !important;
    object-fit: contain;
  }

  &:focus {
    outline: 0;
  }

  @media screen and (min-width: 801px) and (max-width: 862px) {
    padding: 8px 10px;
  }
}

@media (min-width: $desktop) {
  .filter-row {
    &__label {
      &--left {
        font-size: 1rem;
        width: auto;
        margin-left: 0;
      }
    }
  }

  .filter-row-option {
    margin-left: 0.825rem;
  }
}
