.no-results {
  $self: &;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 816px;
    margin: 0 auto;

    @media (max-width: $desktop-min) {
      flex-direction: column;
    }
  }

  &__icon {
    margin: 0 .8rem 0 0;
    text-align: center;

    @media (max-width: $desktop-min) {
      width: 160px;
      margin-right: 0px;
      margin-bottom: 24px;
    }

    &__image {
      max-width: 100%;
    }

    &.littleImage {
      margin-right: 24px;
    }
  }

  &__content {
    text-align: left;

    @media (max-width: $desktop-min) {
      width: 100%;
      text-align: center;
    }
  }

  &__info {
    color: var(--gray-800);
    font-size: 14px;
    line-height: 19px;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0px;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    display: block;
    color: var(--purple);
  }

  &__most-wanted {
    text-align: left;
    margin: 0 auto;

    &__title {
      font-weight: 300;
      font-size: 2rem;
      line-height: 120%;
      color: #32363b;
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      text-align: left;

      @media (max-width: $tablet-mid-1) {
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%;
        margin: 0 0 1rem 1rem;
      }
    }
  }

  .search {
    &__suggestions {
      .slick-slide:not(:first-child) {
        padding-left: 1rem;
      }

      .no-results__most-wanted__title {
        margin-top: 2rem;
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 150%;
  }

  &--type-producer {
    margin: 16px 0px;

    #{$self}__container {
      justify-content: center;
    }
    #{$self}__content {
      flex: initial;
    }
  }

  &.littleImage {
    margin: 0;
  }

  &--type-producer,
  &--type-filter {
    #{$self}__title {
      font-size: 24px;
      margin-bottom: 8px;
      font-weight: bold;
    }
    #{$self}__text {
      color: #343a42;
      font-size: 14px;
      font-weight: 500;
    }

    .search__suggestions { 
      max-width: 960px;
    }

    @media (max-width: $desktop-m) {
      max-width: 80%;
    }

    @media (max-width: $tablet-mid-1) {
      max-width: 100%;
    }
  }

  &--type-filter {
    #{$self}__title {
      font-size: 24px;
      font-weight: normal;
    }
  }
}
