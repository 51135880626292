.product-carousel-list {
  padding: 16px 0px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin: 8px 0px;

  &--gray {
    background: #fafbfc;
    border: 1px solid #f4f4f4;
  }

  &__title {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #343a42;
    line-height: 24px;
    margin-bottom: 24px;
  }

  @media screen and (min-width: $tablet-mid-2) {
    &__title {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }
}
