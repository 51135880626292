@import '../../global/styles/variables';

// VIDEO -------------------------------------------------------------------------------------
#video {
  @media (min-width: $mobile) {
    padding: 0 16px;
    min-height: 202px;
  }

  @media (min-width: $desktop-m) {
    min-height: 408px;
    padding: 0;
    padding-top: 12px;
    margin-bottom: 1.5rem;
  }

  &.hide {
    display: none;
  }
}

.videoWrapper {
  @media (min-width: $mobile) {
    min-height: 202px;
  }

  @media (min-width: $tablet) {
    min-height: 435px;
    position: relative;
    overflow: hidden;
  }
}

.videoWrapper__vimeo-video {
  position: relative;
  padding-bottom: 56.5%;
  display: block;
  overflow: hidden;
  margin-top: 1rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoWrapper__vimeo {
  position: relative;
  display: block;
  overflow: hidden;

  &-play {
    position: absolute;
    top: 45%;
    left: 45%;
    width: 70px;
    height: 46px;
    background-color: #212121;
    border-radius: 14%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    cursor: pointer;
    outline: none;
    border: none;
    z-index: 1;
    opacity: 0.8;

    &:hover {
      background-color: rgba(0, 173, 239, 0.9);
    }

    &:after {
    content: "";
    border-style: solid;
    border-width: 11px 0 11px 19px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
      -moz-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-thumb {
    border-radius: 6px;
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
      background-position: top;
      background-repeat: repeat-x;
      height: 60px;
      padding-bottom: 50px;
      width: 100%;
      -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
      -o-transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
      -moz-transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
      transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: calc(100% / (16 / 9));
    }
  }

  @media (min-width: $mobile) {
    min-height: 210px !important;
    border-radius: 4px;
  }

  @media (min-width: $tablet) {
    min-height: 300px !important;
  }

  @media (min-width: $desktop) {
    min-height: 400px !important;
  }
}

.yt-lite {
  border-radius: 6px;
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* gradient */
.yt-lite::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  background-color: #f00;
  opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}
