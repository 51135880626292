$grey-1: #ffffff;
$grey-2: #fafbfc;
$grey-3: #484e56;
$grey-4: #cecece;
$grey-5: #efefef;
$grey-6: #999999;
$grey-7: #dfe2e6;
$grey-8: #d0d3d6;
$grey-9: #6b7480;
$grey-10: #a9b1ba;
$grey-11: #dce0e3;
$grey-12: #f0f3f7;
$grey-13: #e8e8e8;
$grey-14: #343a42;
$grey-15: #5f6771;
$grey-16: #f4f4f4;

$black-1: #000000;

$blue-1: #1c76d0;
$blue-2: #50c1c3;

$red-1: #ef4e23;
$red-2: #f04e23;
$red-3: #ffb09c;
$red-4: #fc592f;
$red-5: #ed4e2e;
$red-6: #ed4f2e;

$drawer-mask: rgba(0, 0, 0, 0.4);

// COLORS -----------------------------------------------------------------

$white: #ffffff;
$black: #000;
$red: #ef4e23;
$hm: #053d4e;
$title-card: #286678;
$main-title: #343a42;
$main-text: #70767e;
$light: #51c1c3;
$gray: #eee;
$gray-2: #484e56;
$gray-3: #f0f3f7;
$gray-4: #6b7480;
$gray-5: #cecece;
$gray-6: #dfe2e6;
$gray-7: #6d7278;
$gray-medium: #9B9B9B;

$gray-border: #d0d3d6;
$gray-light: #D1D1D1;
$blue:#3498db;
$red-dark: #D0021B;

$bgContent: #FAFBFC;
$text:#333;
$textLight:#848A91;

$color1: #155364;
$color2: #3498DB;
$color3: #7E65D0;
$color4: #F04E23;
$color5: #BC9805;
$color6: #2ECC71;
$color7: #51C1C3;
$color8: #848A91;
$color9: #58ACE3;
$color10: #A18CE3;
$color11: #FF7364;
$color12: #F9D543;
$color13: #51D88C;
$color14: #7CDBDB;

$white: #fff;
$black: #000;

$color-primary: #f04e23;
$color-secundary: #053d4e;
$color-tertiary: #51c1c3;

$highlight: var(--hotmart-primary);


$text-color: $black;

$color-collection: ($color-primary $color-secundary $color-tertiary var(--gray-100) var(--gray-200) var(--gray-300) var(--gray-400) var(--gray-500) var(--gray-600) var(--gray-700) var(--gray-800) var(--gray-900)
var(--hotmart-tertiary-lightest) var(--hotmart-tertiary-lighter) var(--hotmart-tertiary-light) var(--hotmart-tertiary) var(--hotmart-tertiary-dark) var(--hotmart-tertiary-darker));

$color-count: length($color-collection);


// $colorShadow: var(--color-theme);

$color-collection: ($color1 $color2 $color3 $color4 $color5 $color6 $color7 $color8 $color9 $color10 $color11 $color12 $color13 $color14);
$color-count: length($color-collection);
