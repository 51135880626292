.product-card__price,
.product-card-alt__price {
  color: #444444;
  font-weight: bold;
  flex-direction: column;
  min-height: 33px;

  h3 {
    @media (min-width: $mobile) {
      font-size: 16px !important;
    }
  }

  @media (min-width: $tablet) {
    align-items: flex-end;

    h3 {
      font-size: 24px !important;
    }
  }
}
