.season-header {
  background-position: right center;
  padding: 20px 0px 20px 0px;
  text-align: center;
  margin: 0 auto;
  border-radius: 0 0 8px 8px;

  &--results-page {
    padding-bottom: 0;
  }

  .season-header__search {
    .page-container__content {
      display: flex;
      justify-content: center;
    }
  }

  &__container {
    margin: 0 auto;
    padding: 0 25px;
    display: inline-block;
    position: relative;

    &__head {
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 24px;
      text-align: left;
      max-width: 947px;

      &__logo {
        margin: 0 0 12px 0;
        flex: 0 0 38%;
      }

      &__message {
        text-align: left;
        &__h1 {
          color: $white;
          font-size: 24px;
          font-weight: normal;
          strong {
            font-weight: bold;
          }
        }
      }
    }

    #hm-mktplace-search-bar {
      max-width: 821px;
      margin: 0 auto;
    }
  }

  &__image {
    display: initial;
    margin-bottom: 6rem;
    height: 200px;
  }
  

  &__search {
    &-text {
      font-size: 2rem;
      font-weight: var(--font-weight-bold);
      color: var(--white);
    }

    .page-container__content {
      position: absolute;
      top: 7rem;
    }

    @media (min-width: $tablet) {
      &-text {
        margin-bottom: 1rem;
      }

      .page-container__content {
        position: inherit;
        top: auto;
      }
    }
  }

  @media (min-width: $tablet-mid-1) {
    &--results-page {
      padding-bottom: 0;
    }

    &__container {
      &__head {
        flex-direction: row;
        align-items: center;
        margin-bottom: 3.5rem;
        &__logo {
          margin: 0 20px 0 0;
        }
      }
    }
  }
}
