.sparkle-community-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: .375rem;
  background-color: var(--white);
  padding-bottom: 1.5rem;

  min-height: 375.59px;

  @media (min-width: $desktop-min) {
    max-width: 26.5rem;
  }

  &__thumbnails {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 3rem;
    gap: .25rem;
  }

  &__thumbnail-image {
    width: 30% !important;
    border-radius: .15625rem;
  }

  &__thumbnail-image-generic {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: linear-gradient(224deg, rgb(255, 152, 99), rgb(255, 133, 64));
    justify-content: center;
    align-items: center;

    padding-top: 30%;
    padding-bottom: 29%;

    @media (min-width: 601px) and (max-width: 797px) {
      padding-top: 28%;
      padding-bottom: 27%;
    }

    @media (min-width: 900px) and (max-width: 1230px) {
      padding-top: 30%;
      padding-bottom: 29%;
    }

    @media (min-width: 1231px) {
      padding-top: 33%;
      padding-bottom: 33%;
    }

    img {
      width: 2.5rem !important;
      height: 2.5rem !important;
      min-width: 2.5rem !important;
      min-height: 2.5rem !important;
    }
  }

  &__image {
    position: absolute;
    bottom: -2rem;
    left: calc(50% - 2rem);
    width: 4rem;
    height: 4rem;
    border-radius: 20%;
    outline: .3125rem solid var(--white);
  }

  &__title {
    line-height: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--gray-800)
  }

  &__type {
    line-height: 150%;
    font-size: .75rem;
    color: var(--gray-500);
  }
  
  &__data {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2.25rem;
    width: 100%;
    gap: .5rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__amount {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--gray-800);
    }

    &__text {
      font-size: .75rem;
      color: var(--gray-500);
    }
  }

  &__cta {
    width: fit-content;
    padding: .5rem 1rem;
    text-decoration: none;
    border-radius: .375rem;
    color: var(--blue-dark);
    border: .0625rem solid var(--blue);
    transition: all $timing-3 $ease;

    &:hover {
      color: var(--white);
      background-color: var(--blue);
    }

    &:focus {
      color: var(--white);
      background-color: var(--blue);
    }
  }
}