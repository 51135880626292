.filter-modal {
  position: fixed;
  background: $bgContent;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000000;
  transition: all $ease $timing-5;
  opacity: 0;

  &--show {
    opacity: 1;
  }

  &--hide {
    opacity: 0;
  }

  .clean-filter {
    display: flex;
    color: #3b3b3b;
    background: transparent;
    padding: 5px 16px;
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    cursor: pointer;
    align-items: center;
    opacity: 0;
    pointer-events: none;

    &--active {
      opacity: 1;
      pointer-events: all;
    }

    &__icon {
      width: 14px;
      margin-right: 16px;
      color: #3b3b3b;
      transition: all $ease $timing-5;
    }

    &:hover {
      background: $season-button-iconed-hover-background;
      border-color: $season-button-iconed-hover-background;
      color: $season-button-iconed-hover-marker-color;
      .clean-filter__icon {
        color: #ffffff;
      }
    }
  }

  &__header {
    padding: 1rem 0;
    border-bottom: 1px solid #f0f0f0;

    .mobile-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      height: 32px;
      align-items: center;
    }

    &__logo {
      max-width: 180px;
    }

    button {
      background: transparent;
      border: 0;
      svg {
        color: #343a42;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__filters {
    flex: 1;
    overflow: auto;
    padding: 16px 0px;
    .checkbox {
      &__check {
        width: 16px;
        height: 16px;
        min-width: 16px;
        max-width: 16px;
      }
      &__label {
        font-size: 20px;
      }
    }
    &__title {
      color: var(--blue);
      font-weight: bold;
      margin-bottom: 16px;
      align-items: center;
      display: flex;
      &__filter-by {
        font-size: 12px;
        display: block;
      }
      &__label {
        font-size: 20px;
        font-weight: normal;
      }
      &__icon {
        margin-left: 8px;
      }
    }

    .category-filter, 
    .rating-filter, 
    .product-format-filter, 
    .payment-format-filter {
      margin-bottom: 2rem;
    }

    .custom-coupon-filter-container {
      margin-bottom: 2rem;
    }
  }

  &__footer {
    border-top: 1px solid #f0f0f0;
    background-color: #fafbfc;

    &__apply {
      padding: 24px;

      .button-iconed {
        background: var(--blue);
        &__marker {
          color: #ffffff;
          font-size: 16px;
        }
        &__icon {
          width: 16px;
        }
        &:active,
        &:focus,
        &:hover {
          opacity: .6;
        }
      }

      &--items-selected {
        .button-iconed {
          background: var(--blue);
        }
      }
    }
  }
}
