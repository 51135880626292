

.display-card {
  width: 100%;
  overflow: hidden;
  border: 1px solid $grey-3;

  .display-card-hero {
    width: 100%;
    overflow: hidden;
  }

  .display-hero-image {
    width: 100%;
  }

  .hotmarter-image {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
  }

  .display-card-content {
    position: relative;
    padding: 20px;
    padding-top: 40px;
  }

  .display-card-bubble {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .display-card-title {
    font-size: 30px;
    word-break: break-word;
  }

  .display-card-footer {
    display: flex;
    padding: 5px 10px 10px 20px;
    align-items: flex-end;
    justify-content: space-between;
  }
}
