.categories-component {
  margin-top: 2rem;
  padding: 2rem 1rem;
  width: 100%;
  color: var(--gray-800);

  &__title {
    font-size: 2rem;
    font-weight: 300;
    line-height: 120%;
  }

  &__categories {
    margin-top: 2rem;
  }

  &__category {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      .categories-component__image {
        transform: scale(1.05);
      }
    }

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  &__image {
    width: 7.5rem;
    height: 7.5rem;
    transition: 0.3s all $ease;
  }

  &__name {
    font-size: .875rem;
    font-weight: bold;
    line-height: 150%;
    margin-top: 1rem;
  }
}

@media (max-width: $tablet-mid) {
  .categories-component {
    padding: 0;
    background-color: transparent;

    &__title {
      font-size: 1rem;
      font-weight: normal;
      line-height: 150%;
      margin-left: .75rem;
    }

    &__category {
      &:first-of-type {
        margin-left: .75rem;
      }

      &:last-of-type {
        margin-right: .75rem;
      }

      &:hover {
        .categories-component__image {
          transform: none;
        }
      }
    }

    &__categories {
      margin-top: 1rem;
      overflow-x: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
      }
    }

    &__image {
      width: 4.5rem;
      height: 4.5rem;
    }

    &__name {
      font-size: .625rem;
      margin-top: .25rem;
    }
  }
}