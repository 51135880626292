

.category-search {
  width: 100%;
  max-width: 836px;
  margin: 0 auto;

  &__simple {
    margin-bottom: 0;
  }

  .hm-mktplace-search-input-autocomplete {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.13);
    &.is-focused {
      box-shadow: 0 0 0 4px rgba(208, 211, 214, 0.4), 0 0 4px 0 #545b64;
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    margin: 0 auto;
  }
}
