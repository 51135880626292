.content-collapsed {
  &--title {
    font-weight: 800;
    font-size: var(--text-4);

    @media screen and (min-width: $desktop) {
      font-size: 48px;
    }

    &-small {
      display: none;

      @media screen and (min-width: $desktop) {
        display: block;
        font-size: 14px;
      }
    }
  }

  &--subtitle {
    font-weight: 800;
    font-size: var(--text-4);

    @media screen and (min-width: $desktop) {
      font-size: 36px;
    }
  }

  &--description {
    & > * {
      color: var(--gray-600);
      line-height: var(--line-height-lg);
      font-weight: var(--font-weight);

      @media screen and (min-width: $desktop) {
        font-weight: var(--font-weight-light);
      }
    }

    @media screen and (min-width: $desktop) {
      max-width: 800px;
    }

    &, & > p {
      font-size: 14px;
      display: inline-block;

      @media screen and (min-width: $desktop) {
        font-size: 20px;
      }
    }

    & > h2 {
      font-size: 20px;
      margin-bottom: var(--spacer-4);
      display: inline-block;

      @media screen and (min-width: $desktop) {
        font-size: var(--text-5);
        font-weight: 800;
      }
    }

    &-collapsed {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      & > p, & > h2 {
        display: inline;
      }
    }


  }
}
