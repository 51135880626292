.product-card {
  $self: &;
  text-align: initial;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;

  &__featured {
    border-radius: 3.125rem;
    background-color: #f04e23;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;

    &__star {
      width: 1.4375rem;
      height: 1.3125rem;
      color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__buy-button {
    margin-top: 0.4375rem;
    margin-left: -0.375rem;
  }

  .product-image-wrapper {
    border-radius: 0.375rem;
    overflow: hidden;
    border: solid 0.0625rem #f0f3f7;
    border-bottom: 0;
    min-height: 200px;
    background: var(--gray-800);

    .product-image {
      background: #ffffff;
      transition: $timing-5 all $ease;
    }

    &.product-season {
      border: 0.0625rem solid;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .product-card__certification {
    .product-image-wrapper {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0;
    }
  }

  &__black-november {
    border: none;
    border-radius: 0;

    .product-card {
      &__tile {
        height: 32px;
        border: none;
        background-position: right;
        border-radius: inherit;
        margin-bottom: 0;

        &-text {
          font-weight: 900;
          font-size: 1rem;
          line-height: 1.6rem;
          padding-right: 0.5rem;
        }
      }

      &__image {
        width: auto;

        &-wrapper {
          border: none !important;
        }
      }

      @media (max-width: $tablet) {
        &__tile {
          &-text {
            padding-right: 1rem;
          }
        }

        &__image {
          width: 4.5rem;
          margin-left: 0.5rem;
        }
      }
    }

    .product-image-wrapper {
      border: none !important;
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }
  }

  &--new {
    height: 95%;
    border: 1px solid var(--gray-200);

    .product-image-wrapper {
      min-height: 150px;
      border-radius: 0.75rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .product-card {
      &__content {
        min-height: 140px;
      }

      &__title {
        font-size: 18px;
        font-size: 0.875rem;
        line-height: 120%;
        margin: 0.25rem 0;

        &--uppercase {
          text-transform: lowercase;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      &__info {
        height: 4.25rem;
      }

      &__author {
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 0.625rem;
        color: var(--neutral-900);
        font-size: 0.625rem;
      }

      &__rating {
        margin-bottom: 0;
        font-size: 0.75rem;
        margin-right: 0.75rem;

        &__star {
          height: 1rem;
        }
      }

      &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: var(--gray-500);
      }

      &--rating {
        display: flex;
        align-items: center;
        min-height: 1.725rem;
        margin: 0.25rem 0 1rem;
      }
    }
  }

  a {
    text-decoration: none;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__content {
    padding: 0.5rem;
  }

  &__rating {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 0.5rem;

    font-size: 0.625rem;
    font-weight: 900;
    line-height: 100%;
    color: var(--gray-800);

    &__star {
      color: var(--yellow);
      height: 0.625rem;
      margin: 0 0.125rem 0.1rem;
    }

    &__total {
      font-weight: normal;
      margin-left: 0.25rem;
      color: var(--gray-500);
    }
  }

  &__title {
    font-size: 0.75rem;
    line-height: 121%;
    font-weight: bold;
    word-break: break-word;
    color: var(--gray-800);
    transition: all $ease $timing-5;
    max-height: 3.2rem;
    overflow: hidden;
  }

  &__author {
    font-size: 0.75rem;
    color: var(--gray-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__footer {
    display: flex;
    padding: 0 0.625rem 1rem 1rem;
    justify-content: space-between;
    color: $gray-4;
    font-weight: normal;
    flex-direction: column;
  }

  &__price {
    margin-top: 0.5rem;

    &--discount {
      display: flex;

      h3, span {
        font-size: 12px;
        font-weight: 600;
        line-height: 150%;
        color: #707780;

        @media (min-width: $desktop-min) {
          margin-left: 0.25rem;
        }
      }

      h3 {
        text-decoration: line-through;
      }
    }
  }

  &--season {
    .product-image-wrapper {
      border-color: #464b52;
      border-radius: 0.375rem 0.375rem 0 0;
    }
  }

  &:hover {
    background: $gray-3;

    .product-image-wrapper {
      .product-image {
        transform: scale(1.05);
      }
    }

    #{$self}__title {
      color: var(--blue);
    }

    #{$self}__category__text {
      background-color: $white;
    }
  }

  @media screen and (min-width: $mobile-max) {
    &__title {
      font-size: 0.875rem;
      max-height: 4.375rem;
      overflow: hidden;
      line-height: normal;
    }
  }

  @media (min-width: $desktop-min) {
    &--new {
      .product-card {
        &__title {
          font-size: 18px;
          max-height: 2.75rem;
        }

        &__author {
          font-size: 0.875rem;
        }
      }
    }
  }

  @media (min-width: $tablet-max) {
    &--new {
      .product-image-wrapper {
        height: 295px;
      }
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    &__content {
      padding: 1rem;
    }

    &__buy-button {
      margin-left: 0;
    }

    &__title {
      font-size: 1rem;
      max-height: 4.375rem;
      overflow: hidden;
      line-height: normal;
    }

    &__author {
      font-size: 0.875rem;
      line-height: 1.71;
    }

    &__price {
      margin-top: 0;
    }
  }
}

.search-container--product-card {
  @media (min-width: $desktop) {
    .embla {
      &__slide__size_4 {
        min-width: 23.73%;
        margin-left: 1.125rem;
      }

      &__button--next {
        right: -5px;
      }
    }
  }

  .product-card-more-results {
    height: auto;
    margin-bottom: 1.5rem;
  }

  @media (max-width: $tablet-min) {
    .embla__slide__size_4 {
      min-width: 43%;
    }

    .product-card-more-results {
      margin-bottom: 1rem;
    }
  }
}
