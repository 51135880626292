$season-main-bg: #171717;
$season-menu-item-color: #767676;

// Season header
$season-header-background-image: url('/s/static/images/black-november/background.png');
$season-header-background-color: #191C1F;

// Product Card
$season-product-card-background: #282828;
$season-product-card-border: #181818;
$season-product-card-border-hover: #ff3f11;
$season-product-card-discount-bar-background: #101010;
$season-product-card-category-background: #484e56;
$season-product-card-category-color: #ffffff;
$season-product-card-title: #ffffff;

// Product Season Discount Tag
$season-product-tag-color: #ffffff;

// Filter
$season-discount-filter-border: #383a3d;
$season-discount-filter-phrase-background: #1e1e1e;
$season-discount-filter-background: #2d2d2d;
$season-discount-filter-active-background: #ef4a24;
$season-discount-filter-active-phrase-background: #d64225;
$season-discount-filter-active-circle-stroke: #1e1e1e;

// button iconed
$season-button-iconed-border: rgba(208, 211, 214, 0.2);
$season-button-iconed-marker-color: #ff592e;

$season-button-iconed-hover-background: #ef4a24;
$season-button-iconed-hover-marker-color: rgb(255, 255, 255);

// menu
$season-menu-background: #171717;
$season-menu-backdrop: #0e0e0e;
$season-menu-title-color: #ffffff;
$season-menu-link-color: #dfe2e6;
$season-menu-footer-background: #171717;
$season-menu-footer-secondary: #ffffff;

/*****
ABOUT
******/

$season-about-title-color: #ffffff;
$season-about-text-color: #b7b7b7;
$season-about-text-highlight-color: #f15523;
$season-about-cta-color: #ffffff;
$season-about-cta-background: rgba(250, 251, 252, 0.05);
$season-about-cta-background-hover: rgba(255, 63, 17, 0.2);

$season-about-coupons-filter-background: rgba(40, 40, 40, 0.22);
