.selectable-list-counter {
  $self: &;

  &__no-bullets {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }

  &__label {
    padding-top: .1875rem;
  }

  &__item-list {
    max-height: 16rem;
    overflow-y: hidden;

    &--scrollable {
      overflow-y: scroll;
    }
  }

  &__item {
    transition: all $timing-5 $ease;
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: .75rem;
    display: flex;
    align-items: center;

    @media (min-width: $tablet) and (max-width: $desktop-m){
      &--large {
        margin-bottom: 1rem;
      }
    }

    &--selected {
      font-weight: 600;
    }

    &--no-results {
      opacity: 0.4;
      pointer-events: none;
    }

    &__icon {
      margin-right: .5rem;
      height: 1rem;
      transform: translateY(-.0625rem);
      svg {
        width: .625rem;
      }

      .hot-form--custom {
        .hot-form__input:hover ~ .hot-form__label::before,
        .hot-form__input:focus ~ .hot-form__label::before {
          box-shadow: 0 0 0 0.2rem #393b3e4f !important;
        }
      }
      .hot-form--checkbox .hot-form__input:checked ~ .hot-form__label::before {
        background-color: var(--gray-900) !important;
        box-shadow: 0 0 0 var(--input-border-width) var(--gray-900) !important;
      }
    }

    &__label {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 11.25rem;

      @media (max-width: $tablet) {
        width: 14.5rem;
      }
    }

    &__counter {
      margin-left: .5rem;
      color: var(--gray-500);
    }
  }

  .show-all-button {
    cursor: pointer;
    margin-top: .375rem;
    text-align: left;
    display: block;
    font-size: .75rem;
    font-weight: var(--font-weight-bold);
    line-height: 150%;
    color: var(--gray-800);
    text-decoration: underline;

    svg {
      font-size: .625rem;
      margin-left: .625rem;
      transition: $timing-5 all $ease;
      max-width: .5625rem;
      color: var(--blue);
      vertical-align: middle;
    }

    &__open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .filter-fade {
    content  : "";
    position : relative;
    z-index  : 1;
    bottom   : 4rem;
    left     : 0;
    pointer-events   : none;
    background-image : linear-gradient(to bottom,
                      rgba(255,255,255, 0),
                      rgba(255,255,255, 1) 90%);
    width    : 17.25rem;
    height   : 4rem;
    margin-bottom: -4rem;
  }

  .hot-form--checkbox {
    padding: 0 0 0 1.2rem;
  }

  .hot-form__label {
    font-size: .75rem;
    line-height: 1.125rem;
    color: var(--gray-800);

    & > span {
      display: flex;
    }
  }

  .hot-form--checkbox .hot-form__label::before, .hot-form--radio .hot-form__label::before {
    top: .25rem;
    left: -1rem;
    width: .625rem;
    height: .625rem;
    border-radius: .125rem;
  }

  .hot-form--checkbox .hot-form__input:checked ~ .hot-form__label::after {
    left: -1.1875rem;
    top: .0625rem;
  }

  &--horizontal {
    display: flex;
    flex-direction: row;

    .hot-form {
      &__label {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #191C1F;
      }
    }

    .hot-form--checkbox .hot-form__label::before {
      display: none;
    }

    .hot-form--checkbox .hot-form__label::after {
      background-image: url(/s/static/images/black-november/filter-tag-disabled.png);
      background-size: contain;
      display: initial;
      top: .1rem;
      left: 2rem;
    }

    .hot-form--checkbox {
      padding: 0 .5rem;
    }

    #{$self}__item {
      padding: 8px 16px;
      border: 1px solid #c9ced4;
      display: inline-flex;
      align-items: center;
      border-radius: 23px;
      margin-left: 8px;
      -webkit-transition: 0.5s all;
      transition: 0.5s all;
      cursor: pointer;

      &--selected {
        background: var(--black);
        color: var(--white);

        .hot-form--checkbox .hot-form__label::after {
          background-image: url(/s/static/images/black-november/filter-tag-enabled.png)!important;
          left: 2rem!important;
        }
      }
    }
  }
}
