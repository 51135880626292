.product-card-alt {
  background: #ffffff;
  box-shadow: 0px 8px 8px -3px rgba(0,0,0,0.1);
  border-radius: 12px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding: 0.75rem;
  color: var(--gray-800);
  text-align: left;
  transition: box-shadow 0.5s;

  @media screen and (min-width: $desktop) {
    box-shadow: none;

    &:hover {
      box-shadow: 0px 8px 8px -3px rgba(0,0,0,0.1);
    }
  }

  @media (max-width: $tablet-mid) {
    margin-bottom: 0.5rem;
  }

  @media (min-width: $tablet-mid) {
    padding: 1rem;
  }

  .product-link {
    display: flex;
  }

  &--black-november {
    background-color: var(--gray-900);

    .product-card-alt__badge-certification {
      background-color: var(--gray-600);
      border: 1px solid var(--gray-500);
    }
    
    .product-card-alt__rating__total {
      color: var(--gray-200) !important;
    }
  }

  &--creator {
    background-color: var(--black) !important;

    .product-card-alt {
      &__info {
        margin-top: 0.75rem;
      }
    }

    @media (max-width: $tablet) {
      .product-card-alt__price {
        min-height: auto;
        margin: 0.25rem 0;
      }
    }
  }

  .discount-banner--creator {
		max-width: max-content !important;
		border: none;

		.discount-banner__value {
			width: 100%;
			font-size: 10px;
      background-color: var(--brand-primary-lighter) !important;

			span {
				color: var(--white) !important;
			}
		}

    @media (max-width: $tablet) {
      margin-left: 0;
    }
	}

  a {
    display: flex;
    text-decoration: none;
    color: var(--gray-800);
  }

  .product-alt {
    border-radius: 0.375rem;
    margin-right: 0.75rem;

    @media (min-width: $tablet-max) {
      width: 11.5rem !important;
      height: 11.5rem !important;
      margin-right: 0.75rem;
    }
  }

  &__image {
    border-radius: 0.375rem;

    @media (min-width: $mobile) {
      width: 66px !important;
      height: 72px !important;
    }

    @media (min-width: $desktop) {
      width: 184px !important;
      height: 184px !important;
    }
  }

  &__price {
    &--discount {
      div {
        font-size: 12px !important;
        line-height: 150%;
        text-decoration-line: line-through;
        color: #9ea4ac !important;
      }
    }

    svg {
      max-width: 80px;
    }
  }

  &__info {
    flex-basis: 35%;

    .product-card {
      &__tile {
        width: 160px !important;
      }

      &__image {
        margin-left: 0;
        height: 22px;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-left: 0.45rem;

    @media (min-width: $tablet-min) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__content-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 70%;
    height: 100%;

    @media (max-width: $tablet) {
      flex-basis: 60%;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.1875rem;

    @media (min-width: $tablet-max) {
      font-size: 1.25rem;
      line-height: 120%;
    }
  }

  &__author,
  &__rating__total {
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: var(--gray-500);

    @media (min-width: $tablet-max) {
      font-size: 0.75rem;
      line-height: 150%;
    }
  }

  &__black-november {
    .product-card__tile {
      width: 232px;
      height: 32px;
      border-radius: 6px;
      background-position: right;
      margin-top: 1rem;
      padding-right: 0.8rem;

      &-text {
        font-style: normal;
        font-weight: 900;
        font-size: 1rem;
      }
    }

    @media (max-width: $mobile-m) {
      .product-card {
        &__tile {
          width: 180px;
          height: 50px;
          margin: 0.5rem 0 0;

          &-text {
            font-size: 0.8rem;
            margin-left: 1.5rem;
            padding-right: 0;
          }
        }
      }
    }

    @media (max-width: $tablet) {
      .product-card {
        &__image {
          width: 6.5rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &--new {
    border: 1px solid var(--gray-200);
    padding: 0;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    .product-alt {
      width: 315.45px !important;
      height: 250px !important;
      margin-right: 0;
    }

    .tag__best-rated {
      margin-right: 0.5rem;
    }

    .product-card-alt {
      &__title {
        font-weight: 800;
        font-size: 18px;
      }

      &__author {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: var(--gray-900);
        margin-bottom: 0.25rem;
      }

      &__content {
        padding: 1rem;
        margin-left: 0;

        &-top {
          flex-basis: auto;
        }
      }

      &__classes {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--blue-darker);

        div {
          display: flex;
          align-items: center;
        }

        span {
          margin-right: 0.5rem;
          height: 18px;
        }
      }

      &__total {
        margin-right: 1.25rem;
      }

      &__hours {
        margin-right: 1.625rem;
      }

      &__rating {
        display: flex;
        font-size: 0.75rem;
        margin: 0 0.85rem 0 0;
        letter-spacing: 0.02em;

        &__star {
          height: 1rem;
          width: auto;
        }
      }

      &__status {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
      }

      &__description {
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        max-height: inherit;
        margin-top: 0.25rem;
      }

      &__price {
        align-items: baseline;
        margin-top: 1.25rem;
        min-height: auto;

        h3 {
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.5rem;
          color: var(--gray-800);
        }
      }
    }

    @media (max-width: $tablet) {
      padding: 0.5rem;

      .product-alt {
        width: 70px !important;
        height: 70px !important;
      }

      .product-card-alt {
        &__author {
          font-weight: 700;
          font-size: 10px;
          line-height: 150%;
        }

        &__status {
          margin-bottom: 0;
        }

        &__content {
          padding-top: 0;
          width: 82%;

          &-top {
            flex-basis: auto;
            width: 100%;
          }
        }

        &__title {
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          margin-bottom: 0.25rem;
        }

        &__price {
          margin-top: 1rem;
          font-size: 0.875rem;
          line-height: 150%;
        }
      }

      .product-card__buy-button {
        width: 100%;

        .buy-button {
          width: inherit;
        }
      }
    }

    @media (min-width: $desktop) {
      .product-card-alt {
        &__image {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  &__rating {
    width: fit-content;
    font-size: 0.6875rem;
    font-weight: 900;
    text-decoration: none;
    margin-top: 0.25rem;

    &__star {
      color: #efba0f;
      width: 0.5625rem;
      margin-left: 0.125rem;
    }

    &__total {
      font-weight: normal;
      color: var(--gray-500);
      margin-left: 0.25rem;
    }

    &__pill {
      background-color: #ef4e23;
      border-radius: 4rem;
      font-size: 0.5rem;
      font-weight: bold;
      line-height: 150%;
      color: #fff;
      text-transform: uppercase;
      padding: 0.125rem 0.5rem;
      margin-left: 0.5rem;
    }

    @media (min-width: $tablet-max) {
      font-size: 0.75rem;
      line-height: 150%;
    }
  }

  &__description {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;
    color: var(--gray-800);
    max-width: 34vw;
    max-height: 2.25rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5rem;

    @media (min-width: $tablet-max) {
      max-width: 32.25rem;
    }

    @media (max-width: $mobile-l) {
      display: none;
    }
  }

  &__badge {
    display: flex;
    align-items: center;
    padding: 0.125rem 0.5rem;
    border-radius: 3rem;
    font-size: 0.75rem;
    line-height: 150%;
    color: var(--white);
    width: fit-content;
    margin-top: 0.5rem;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.25rem;
    }

    &-certification {
      background-color: var(--blue);
    }

    @media (min-width: 50.75rem) and (max-width: 74.875rem) {
      margin-top: 0.5rem;
    }
  }

  &__price {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.75rem;

    @media (min-width: $tablet-min) {
      text-align: right;
      margin-top: 0;
    }

    div {
      font-size: 1rem;
      line-height: 120%;
      font-weight: normal;

      @media (min-width: $tablet-max) {
        font-size: 1.5rem;
        line-height: 120%;
        color: var(--gray-800);
        margin-top: 0;
      }
    }
  }
  &__learn-more {
    font-size: 1rem;
    font-weight: normal;
    color: var(--blue-dark);
    display: flex;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 0.75rem;

    justify-content: flex-end;

    & > div {
      padding-left: 0.5rem;
    }
  }

  @media (min-width: $mobile-mid) {
    &__price {
      white-space: nowrap;

      svg {
        max-width: initial;
      }
    }
  }

  @media (max-width: $tablet) {
    min-width: 100%;
    min-height: 96px;

		&.product-card-alt--creator {
			.product-card-alt__content-top {
				flex-basis: auto;
			}
		}
  }
}
