:root {
  --dropdown-menu-width: auto !important;
}

hot-dropdown {
  position: absolute;

  button {
    > svg {
      height: 0.75rem;
      width: 0.75rem;
    }
  }

  hot-dropdown-menu-header {
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }

  hot-dropdown-menu-item {
    --white: transparent;
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
    white-space: nowrap;

    @media (max-width: $tablet) {
      padding: 0.75rem 1rem;
    }

    > .dropdown-menu-item__icon {
      margin-left: -0.25rem;
      margin-right: 0.5rem;
      vertical-align: middle;
      width: 1.5rem !important;
      text-align: center;
    }

    &.is-selected {
      font-weight: bold;
    }
  }

  .ellipsis-button {
    border-radius: 2rem;
  }

  .ellipsis-position {
    margin-left: -0.4rem;
    margin-bottom: 0.75rem;
  }
}
