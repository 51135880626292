.carousel {
  &--scroll {
    scroll-snap-type: x mandatory;
  }

  &--item {
    list-style: none;
    flex-shrink: 0;

    &-snap-point {
      scroll-snap-align: start;
    }
  }

  &--controls {
    &-disabled {
      opacity: 0.3;
    }
  }


}
