.view-toggle {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    font-size: .75rem;
    line-height: 150%;
    color: var(--gray-500);
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: .25rem;
    }

    svg {
      width: .625rem;
      height: .625rem;
      margin-right: .25rem;
    }

    &-active {
      font-weight: 700;
      color: var(--gray-800);
  
      svg {
        color: var(--blue);
      }
    }
  }
}